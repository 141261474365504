import React, { useRef, useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataManager, {DrawingState, DrawingControls, ControlActions} from "../data/dataManager";
import {
  faSave, faSlash, faPen,
  faSquareFull, faFont,
  faArrowPointer, faRotateLeft, faFilePdf, faEraser, faBorderStyle} from '@fortawesome/free-solid-svg-icons';
import {faSquare, faClipboard, faSquareDashed} from '@fortawesome/free-regular-svg-icons';
import "../../styles/session_toolbar.css";

const SessionToolBar = () => {
  const sessionUrlRef = useRef(null);
  const [copiedLabel, setCopiedLabel] = useState("");
  const [displayNotificationBar, setDisplayNotificationBar] = useState(false);
  //let copiedLabel = "";

  const handleCopy = () => {
    let text = (!sessionUrlRef.current) ? "" : sessionUrlRef.current.value;
    const input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    // console.log('Text copied to clipboard');
    setCopiedLabel("Copied");
  }

  useEffect(() => {
    DataManager.addNotificationTextCallback("sessionToolBar", (text) => {
      setDisplayNotificationBar(!!text && text.length > 0);
    });
  },[]);


  return (
    <div className="sessionurl overlay" style={{
        top : !!displayNotificationBar ? "45px" : "10px",
        width: (copiedLabel.size == 0) ? "360px" : "420px",
        paddingLeft: "20px",
        margin: "auto",
        zIndex: 5000
      }}>
      <text style={{
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: "14px"
      }}>Session Url: </text>
      <input ref={sessionUrlRef} type="text" value={window.location} style={{
        zIndex: 2000
      }}/>
      <div className="btn session" onClick={handleCopy}>
        <FontAwesomeIcon icon={faClipboard} aria-hidden="true"/>
      </div>
      <text style={{
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: "13px",
        color: (copiedLabel.includes("Error")) ? "red" : "green"
      }}>{copiedLabel}</text>
    </div>
  );
};

export default SessionToolBar;
