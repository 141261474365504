import BaseTool from "./base/baseTool"
import DataManager, {DrawingState, DrawingControls, SelectActions} from "../dataManager"
import EventManager from "../eventManager"

// const SelectActions = {
//   Move : "move",
//   Edit : "edit"
// };

class SelectorTool extends BaseTool {
  constructor(){
    super();
    this.state = {
      action : "move",//SelectActions.Move,
      editSelectionVisibleCallback : null,
      setCurrentSelectActionCallback : null,
    };

    SelectorTool.instance = this;
  }

  setSelectAction(action){
    if(this.state.setCurrentSelectActionCallback != null){
      this.state.setCurrentSelectActionCallback(action);
    }
    this.state.action = action;
  }

  getSelectAction(){
    return this.state.action;
  }

  setEditSelectionVisibleCallback(callback) {
    this.state.editSelectionVisibleCallback = callback;
  }

  setCurrentSelectActionCallback(callback) {
    this.state.setCurrentSelectActionCallback = callback;
  }

  onSelectorSelectCompleted(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext) {

    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    let userId = DataManager.getUserId();
    let hostname = DataManager.getCurrentHostName();

    let width = (e.clientX || e.changedTouches[0].clientX) - current.x;
    let height = (e.clientY || e.changedTouches[0].clientY) - current.y;
    current.maxWidth = (current.maxWidth < width) ? width : current.maxWidth;
    current.maxHeight = (current.maxHeight < height) ? height : current.maxHeight;

    let canvasWidth = overlayCanvas.width;
    let canvasHeight = overlayCanvas.height;

    let info = {
      x : current.x,
      y : current.y,
      w : width,
      h : height
    };

    // console.log('CALLING SELECT MOUSE UP, info: ' + JSON.stringify(info)
    //   + ', canvasHeight: ' + canvasHeight
    //   + ', canvasWidth: ' + canvasWidth);

    let selectedArea = //(current.state == DrawingState.None)
    (current.state == DrawingState.Drawing)
    ? {
      x: info.x / canvasWidth,
      y: info.y / canvasHeight,
      w: info.w / canvasWidth,
      h: info.h / canvasHeight
    }
    : ((current.state == DrawingState.Selected) ? {
        x: current.selectedArea.x / canvasWidth,
        y: current.selectedArea.y / canvasHeight,
        w: current.selectedArea.width / canvasWidth,
        h: current.selectedArea.height / canvasHeight
      } : {});

      // console.log('CALLING SELECT MOUSE UP, selectedArea: '
      //   + JSON.stringify(selectedArea));


    //TODO First Select all items  then draw final rect;
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        sessionId : sessionId,
        userId : userId,
        selectedArea : selectedArea })
    };

    fetch('https://'+hostname+':8080/action/select/actions', requestOptions).then((response) => {
        if(response.ok){
          return response.json();
        }
    }).then((data) => {
        //TODO: see why data when drawn gets reverted order
        // console.log('>>>>> DATA AFTER SELECTED: ' + JSON.stringify(data));
        overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);

        let localUserCanvas = overlayCanvas;
        let localUserCanvasContext = overlayCanvasContext;

        EventManager.onRefreshEvent(data,
          canvas, canvasContext,
          overlayCanvas, overlayCanvasContext,
          localUserCanvas, localUserCanvasContext);

        let style = {
          borderColor : current.color,
          borderWidth : 1
        };

       //TODO: update info to the new selected area
       current.selectedArea = {
         x : data.selectedArea.x,
         y : data.selectedArea.y,
         width : data.selectedArea.w,
         height : data.selectedArea.h
       };

        info.x = current.selectedArea.x * canvas.width;
        info.y = current.selectedArea.y * canvas.height;
        info.w = current.selectedArea.width * canvas.width;
        info.h = current.selectedArea.height * canvas.height;
        info.selectedDelta = data.selectedDelta;

        this.draw(info, style, canvas, overlayCanvasContext, true, true, sessionId)

        current.state = DrawingState.Selected;
        if(!!data.selected
          //&& data.selected.length > 0
          && data.selected.length == 1
          && !!this.state.editSelectionVisibleCallback){
          this.state.editSelectionVisibleCallback(true);
        }
        // console.log('SELECTED LENGTH: ' + data.selected.length);

        current.selectedObjects = data.selected;
        DataManager.setCurrentDrawingTool(current);
    });
  }

  onRedrawSelectedObjects(selectedObjects, selectedOffsets,
      canvas, localContext, dx, dy, final, emit) {

    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
     let width = canvas.width;
     let height = canvas.height;


     for(let i = 0; i < selectedObjects.length; i++)
     {
        switch(selectedObjects[i].control)
        {
           case DrawingControls.Text: {
             let info = {
               x    :  dx + selectedOffsets[i].dx,
               y    :  dy + selectedOffsets[i].dy,
               text : selectedObjects[i].text,
               textBoxData: selectedObjects[i].textBoxData,
               selected : !final
             };

             let style = {
                color:      selectedObjects[i].color,
                fontSize:   selectedObjects[i].fontSize,
                fontFamily: selectedObjects[i].fontFamily,
                fontStyle:  selectedObjects[i].fontStyle
              };

              let textControl = DrawingControls.getToolInstance(DrawingControls.Text);
              textControl.draw(info, style, canvas, localContext, final,  emit /*true*/, sessionId);

              selectedObjects[i].x = info.x / width;
              selectedObjects[i].y = info.y / height;
            }
           break;
           case DrawingControls.Line: {
             //TODO: may need to look at the Line draw implementation
             // and make sure that it can handle overlay drawing instead of
             // being final all the time
             //TODO: need to fix bugs when moving straight line
             let info = {
               x    :  dx + selectedOffsets[i].dx0,
               y    :  dy + selectedOffsets[i].dy0,
               x1   :  dx + selectedOffsets[i].dx1,
               y1   :  dy + selectedOffsets[i].dy1,
               selected : !final
             };

             let style = {
               color: selectedObjects[i].color
             }

             let lineControl = DrawingControls.getToolInstance(DrawingControls.Line);
             // console.log('KS: SELECTED LINE MOVED: ' + final);
             lineControl.draw(info, style, canvas, localContext, final,  emit, sessionId);

             selectedObjects[i].x0 = info.x / width;
             selectedObjects[i].y0 = info.y / height;
             selectedObjects[i].x1 = info.x1 / width;
             selectedObjects[i].y1 = info.y1 / height;
           }
           break;
           case DrawingControls.Rect:
           case DrawingControls.FilledRect: {

             let info = {
               x    :  dx + selectedOffsets[i].dx,
               y    :  dy + selectedOffsets[i].dy,
               w    :  selectedOffsets[i].dw,
               h    :  selectedOffsets[i].dh,
               selected : !final
             };

             let style = {
               color: selectedObjects[i].color,
               backgroundColor : selectedObjects[i].color
             }

             //console.log('DRAWING RECT: ' +  JSON.stringify(selectedObjects[i]));

             let rectControl = DrawingControls.getToolInstance(selectedObjects[i].control);
             rectControl.draw(info, style, canvas, localContext, final,  emit, sessionId);

             selectedObjects[i].x = info.x / width;
             selectedObjects[i].y = info.y / height;
             selectedObjects[i].w = info.w / width;
             selectedObjects[i].h = info.h / height;
           }
           break;
           case DrawingControls.Image: {
             let info = {
               x    :  dx + selectedOffsets[i].dx,
               y    :  dy + selectedOffsets[i].dy,
               w    :  selectedOffsets[i].dw,
               h    :  selectedOffsets[i].dh,
               selected : !final
             };

             let style = {
               image : selectedObjects[i].image
             };
             //console.log('SELECTED IMAGE: info:' + JSON.stringify(info));
             //console.log('SELECTED IMAGE: style:' + JSON.stringify(style));
             let imageControl = DrawingControls.getToolInstance(selectedObjects[i].control);
             imageControl.draw(info, style, canvas, localContext, final,  emit, sessionId);

             selectedObjects[i].x = info.x / width;
             selectedObjects[i].y = info.y / height;
             selectedObjects[i].w = info.w / width;
             selectedObjects[i].h = info.h / height;
           }
           break;
           case DrawingControls.StraightLine: {
               let info = {
                 x0    :  dx + selectedOffsets[i].dx0,
                 y0    :  dy + selectedOffsets[i].dy0,
                 x1    :  dx + selectedOffsets[i].dx1,
                 y1    :  dy + selectedOffsets[i].dy1,
                 selected : !final
               };

               let style = {
                 color: selectedObjects[i].color
               }

               let lineControl = DrawingControls.getToolInstance(DrawingControls.StraightLine);
               lineControl.draw(info, style, canvas, localContext, final,  emit, sessionId);

               selectedObjects[i].x0 = info.x0 / width;
               selectedObjects[i].y0 = info.y0 / height;
               selectedObjects[i].x1 = info.x1 / width;
               selectedObjects[i].y1 = info.y1 / height;
           }
           break;
           case DrawingControls.StraightArrow: {
               let info = {
                 x0    :  dx + selectedOffsets[i].dx0,
                 y0    :  dy + selectedOffsets[i].dy0,
                 x1    :  dx + selectedOffsets[i].dx1,
                 y1    :  dy + selectedOffsets[i].dy1,
                 selected : !final
               };

               let style = {
                 color: selectedObjects[i].color
               }

               let arrowControl = DrawingControls.getToolInstance(DrawingControls.StraightArrow);
               arrowControl.draw(info, style, canvas, localContext, final,  emit, sessionId);

               selectedObjects[i].x0 = info.x0 / width;
               selectedObjects[i].y0 = info.y0 / height;
               selectedObjects[i].x1 = info.x1 / width;
               selectedObjects[i].y1 = info.y1 / height;
           }
           break;
           case DrawingControls.Eraser: {
             let info = {
               x      :  dx + selectedOffsets[i].dx,
               y      :  dy + selectedOffsets[i].dy,
               radius :  selectedOffsets[i].dr,
               overlayWidth : canvas.width,
               overlayHeight : canvas.height,
               selected : !final
             };

             let style = {
               color : selectedObjects[i].color,
               outline : false
             };

             let eraserControl = DrawingControls.getToolInstance(DrawingControls.Eraser);
             eraserControl.draw(info, style, canvas, localContext, final,  emit, sessionId);
             let diagonal = Math.sqrt((width*width)+(height*height));

             selectedObjects[i].x = info.x / width;
             selectedObjects[i].y = info.y / height;
             selectedObjects[i].r = info.radius / diagonal;

             // console.log('KS: SELECTED OBJECTS: ' + JSON.stringify(selectedObjects[i]));
           }
           break;
         }
     }
  }

  onInit(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
      this.setSelectAction(SelectActions.Move);
  }

  onComplete(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
        // console.log('SETTING EDIT SELECTOR NOT VISIBLE');
        this.state.editSelectionVisibleCallback(false);
  }

  onMouseDown(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();

    // console.log('SELECTOR TOOL ON MOUSE DOWN DrawingState: '
    //    + JSON.stringify(current));

    if(!!this.state.editSelectionVisibleCallback){
      this.state.editSelectionVisibleCallback(false);
    }

    if (current.state != DrawingState.None
      && current.state != DrawingState.Selected) {
         return;
    }

    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;
    current.maxWidth = 0;
    current.maxHeight = 0;

    if(current.state == DrawingState.None)
    {
      current.state = DrawingState.Drawing;
    }

    if(current.state == DrawingState.Selected)
    {
      let width = canvas.width;
      let height = canvas.height;

      //console.log('SELECTOR MOUSE DOWN: ' + JSON.stringify(current));
      current.selectedDelta = {
          dx : current.selectedArea.x - (current.x / canvas.width),
          dy : current.selectedArea.y - (current.y / canvas.height)
      };

      current.selectedOffsets = [];
      for(let i = 0; i < current.selectedObjects.length; i++)
      {
        let deltaObj = {};
        switch(current.selectedObjects[i].control){
          case DrawingControls.Text: {
            let objX = (current.selectedObjects[i].x * width);
            let objY = (current.selectedObjects[i].y * height);
            deltaObj = {
              control : current.selectedObjects[i].control,
              dx : objX - current.x,
              dy : objY - current.y
            };
          }
          break;
          case DrawingControls.Line:
          case DrawingControls.StraightLine:
          case DrawingControls.StraightArrow: {
            let objX0 = (current.selectedObjects[i].x0 * width);
            let objY0 = (current.selectedObjects[i].y0 * height);
            let objX1 = (current.selectedObjects[i].x1 * width);
            let objY1 = (current.selectedObjects[i].y1 * height);
            deltaObj = {
              control : current.selectedObjects[i].control,
              dx0 : objX0 - current.x,
              dy0 : objY0 - current.y,
              dx1 : objX1 - current.x,
              dy1 : objY1 - current.y
            };
          }
          break;
          case DrawingControls.Rect:
          case DrawingControls.FilledRect:
          case DrawingControls.Image: {
            let objX = (current.selectedObjects[i].x * width);
            let objY = (current.selectedObjects[i].y * height);
            let objW = (current.selectedObjects[i].w * width);
            let objH = (current.selectedObjects[i].h * height);
            deltaObj = {
              control : current.selectedObjects[i].control,
              dx : objX - current.x,
              dy : objY - current.y,
              dw : objW,
              dh : objH
            };
          }
          break;
          case DrawingControls.Eraser: {
            let objX = (current.selectedObjects[i].x * width);
            let objY = (current.selectedObjects[i].y * height);
            let diagonal = Math.sqrt((width*width)+(height*height));
            let objR = (current.selectedObjects[i].r * diagonal);
            deltaObj = {
              control : current.selectedObjects[i].control,
              dx : objX - current.x,
              dy : objY - current.y,
              dr : objR
            };
          }
          break;
        }

        // console.log('>>> SELECTED OBJECT ['+i+'] : ' + JSON.stringify(current.selectedObjects[i]));
        // console.log('>>> SELECTED OFFSET ['+i+'] : ' + JSON.stringify(deltaObj));

        if(i == current.selectedObjects.length)
        {
          current.selectedOffsets.push_back(deltaObj);
          continue;
        }

        current.selectedOffsets[i] = deltaObj;
      }
      current.state = DrawingState.Moving;
    }
    // console.log('>> ALL OFFSETS: ' + JSON.stringify(current.selectedOffsets));
    DataManager.setCurrentDrawingTool(current);
  }

  onMouseMove(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();

    if(current.state == DrawingState.Drawing)
    {
       let width = (e.clientX || e.touches[0].clientX) - current.x;
       let height = (e.clientY || e.touches[0].clientY) - current.y;
       current.maxWidth = (current.maxWidth < width) ? width : current.maxWidth;
       current.maxHeight = (current.maxHeight < height) ? height : current.maxHeight;

       let info = {
         x : current.x,
         y : current.y,
         w : width,
         h : height
       };

       let style = {
         borderColor : current.color,
         borderWidth : 1
       };

       DataManager.setCurrentDrawingTool(current);
       overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
       this.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);
   }

   //if(current.state == DrawingState.Selected)
   if(current.state == DrawingState.Moving)
   {
     // console.log('<<<<< MOVING SELECTED OBJECTS: '
     //  + JSON.stringify(current.selectedObjects) + ' >>>>>>>>>');
     //let dx = (e.clientX || e.touches[0].clientX) - current.x;
     //let dy = (e.clientY || e.touches[0].clientY) - current.y;
     //current.maxWidth = (current.maxWidth < dx) ? dx : current.maxWidth;
     //current.maxHeight = (current.maxHeight < dy) ? dy : current.maxHeight;

     let x = (e.clientX || e.touches[0].clientX);
     let y = (e.clientY || e.touches[0].clientY);

     let info = {
       x : x,
       y : y,
       w : current.selectedArea.width * canvas.width, //current.maxWidth,
       h : current.selectedArea.height * canvas.height, //current.maxHeight,
       selectedObjects : current.selectedObjects,
       selectedOffsets : current.selectedOffsets,
       selectedDelta : current.selectedDelta
     };

     let style = {
       borderColor : current.color,
       borderWidth : 1
     };

     DataManager.setCurrentDrawingTool(current);
     overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
     //TODO: get the below working first
     //this.onRedrawSelectedObjects(current.selectedObjects, canvas, overlayCanvasContext, x, y, false, true);
     this.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);
   }
  }

  onMouseUp(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    //TODO: on mouse up the items need to be redrawn as final
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    let userId = DataManager.getUserId();
    let hostname = DataManager.getCurrentHostName();

    //console.log('MOUSE UP SELECTOR STATE: ' + JSON.stringify(current.state));
    if(current.state == DrawingState.Drawing){
      this.onSelectorSelectCompleted(e, canvas, canvasContext,
          overlayCanvas, overlayCanvasContext);
    }

    //if(current.state == DrawingState.Selected){
    if(current.state == DrawingState.Moving){
        // TODO: 1. undo selection: >>>>
        // TODO: 2. store updated object locations in db duri
        let requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            sessionId : sessionId,
            userId : userId,
            selectedObjects : current.selectedObjects })
        };

        fetch('https://'+hostname+':8080/action/deselect/actions', requestOptions).then((response) => {
            if(response.ok){
              return response.json();
            }
        }).then((data) => {
            //current.selectedObjects = [];
            current.state = DrawingState.None;
            // console.log("DESELECT IS DONE!!! " + JSON.stringify(data.data));

            let dx = (e.clientX || e.changedTouches[0].clientX) - current.x;
            let dy = (e.clientY || e.changedTouches[0].clientY) - current.y;
            current.maxWidth = (current.maxWidth < dx) ? dx : current.maxWidth;
            current.maxHeight = (current.maxHeight < dy) ? dy : current.maxHeight;

            let x = (e.clientX || e.changedTouches[0].clientX);
            let y = (e.clientY || e.changedTouches[0].clientY);


            overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
            this.onRedrawSelectedObjects(current.selectedObjects, current.selectedOffsets,
               canvas, canvasContext, x, y, true, true);
            current.selectedObjects = [];
            current.selectedOffsets = [];
            current.selectedDelta = {
              dx : 0,
              dy : 0
            };
            DataManager.setCurrentDrawingTool(current);
            this.doRefresh(data, canvas, canvasContext, overlayCanvas, overlayCanvasContext);
      });
    }
  }

  doRefresh(data, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let userId = DataManager.getUserId();
    let emitData = {
      sessionId : data.session,
      userId : userId,
      data : data.data
    }
    let localUserCanvas = overlayCanvas;
    let localUserCanvasContext = overlayCanvasContext;
    EventManager.onRefreshEvent(data,
      canvas, canvasContext,
      overlayCanvas, overlayCanvasContext,
      localUserCanvas, localUserCanvasContext);
    EventManager.onRefreshEmit(emitData);
  }

  coverSelectedArea(selectedArea, canvas, context, sessionId){
    // console.log('COVER SELECTED AREA CALLED: ' + JSON.stringify(selectedArea));
    let coverSelectedAreaSquare = DrawingControls.getToolInstance(DrawingControls.FilledRect);
    let width = canvas.width;
    let height = canvas.height;

    let info = {
      x : selectedArea.x * width,
      y : selectedArea.y * height,
      w : selectedArea.w * width,
      h : selectedArea.h * height
    };

    let style = {
      backgroundColor : 'white'
    }

    coverSelectedAreaSquare.draw(info, style, canvas, context, false, false, sessionId);
  }

  drawEmittedData(data,
    canvas, context,
    overlayCanvas, overlayContext,
    userId, sessionId){
      let width = canvas.width;
      let height = canvas.height;

      let info = {
        x : data.x * width,
        y : data.y * height,
        w : data.w * width,
        h : data.h * height,
        //text : data.text,
        //selected : data.selected,
        selectedObjects : data.selectedObjects,
        selectedOffsets : data.selectedOffsets,
        selectedDelta : data.selectedDelta
      };

      let style = {
        color: data.color,
        fontSize: data.fontSize,
        fontFamily: data.fontFamily,
        fontStyle: data.fontStyle
       };

      // console.log('DRAW EMITTED SELECTED: ' + info.selectedObjects.length);
      // console.log('DRAW EMITTED SELECTED: ' + JSON.stringify(info.selectedObjects));

      overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      if(data.selectedObjects.length > 0){
        this.coverSelectedArea(data.selectedArea, canvas, context, sessionId);
        this.onRedrawSelectedObjects(data.selectedObjects, data.selectedOffsets, canvas,
         overlayContext, info.x, info.y, false, false);
        this.draw(info, style, canvas, overlayContext,
           false, false, sessionId);
      } else {
        this.draw(info, style, canvas, overlayContext,
          data.final, false, sessionId);
      }
  }

  draw(info, style, canvas, context, isFinal, emit, sessionId){

    const { x, y, w, h, selectedObjects, selectedOffsets, selectedDelta = { dx : 0, dy : 0} } = info;
    const { borderColor = 'black', borderWidth = 2 } = style;

    if(!!selectedObjects && selectedObjects.length > 0){
      // TODO: get this to work when selected objects are present
      // and should not be transmitted to broadcase
      this.onRedrawSelectedObjects(selectedObjects, selectedOffsets, canvas,
       context, x, y, isFinal, false);
    }

    //TODO: need to make sure selection box is always drawn..
    // Width and height passed is 0
    //console.log('ABOUT TO DRAW A SELECT WRAPPER: ' + JSON.stringify(info));
    context.beginPath();
    context.strokeStyle = borderColor;
    context.lineWidth = borderWidth;
    context.setLineDash([5, 10]);
    context.rect(x + (selectedDelta.dx * canvas.width), y + (selectedDelta.dy * canvas.height), w, h);
    context.stroke();
    context.setLineDash([]);
    //console.log('DONE DRAWING A SELECT WRAPPER');

    if (!emit) { return; }

    let width = canvas.width;
    let height = canvas.height;
    let userId = DataManager.getUserId();
    let drawObjectId = DataManager.getCurrentObjectId();
    let current = DataManager.getCurrentDrawingTool();

    let selectedArea = //(isFinal && current.state == DrawingState.None)
        (isFinal && current.state == DrawingState.Drawing)
        ? { x: x / width, y: y / height, w: w / width, h: h / height}
        :// ((current.state == DrawingState.Selected) ? {
          ((current.state == DrawingState.Moving) ? {
            x: current.selectedArea.x / width,
            y: current.selectedArea.y / height,
            w: current.selectedArea.width / width,
            h: current.selectedArea.height / height
          } : {});

    //TODO: need to show selection outline on the remote client as well

    if(!!isFinal && current.state != DrawingState.Selected)
    {
      current.state = DrawingState.Selected;
      DataManager.setCurrentDrawingTool(current);
    }

    EventManager.onDrawingDataEmit({
      sessionId: sessionId,
      userId: userId,   //userID will be set only when emit is set to true, meaning user drew the line
      actingUserId: userId,
      objectId: drawObjectId,
      control: DrawingControls.Selector,
      final: false,
      selectDone: isFinal,
      drawingState: current.state,
      x: x / width,
      y: y / height,
      w: w / width,
      h: h / height,
      color : borderColor,
      selectedArea : selectedArea,
      selectedObjects : (!selectedObjects) ? [] : selectedObjects,
      selectedOffsets : (!selectedOffsets) ? [] : selectedOffsets,
      selectedDelta : selectedDelta
    });
  }
}

const instance = new SelectorTool();
Object.freeze(instance);
export default instance;
