import React, { useRef, useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave, faSlash, faPen,
  faSquareFull, faFont,
  faArrowPointer, faRotateLeft, faFilePdf, faEraser, faBorderStyle} from '@fortawesome/free-solid-svg-icons';
import {faSquare, faClipboard, faSquareDashed} from '@fortawesome/free-regular-svg-icons';
import DataManager, {DrawingState, DrawingControls} from "../data/dataManager";
import TextTool from "../data/tools/textTool";

import "../../styles/board.css";

export const FontOptions = [
  { value: 'Arial', label: 'Arial' },
  { value: 'Calibri', label: 'Calibri' },
  { value: 'Courier New', label: 'Courier New' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Helvetica', label: 'Helvetica' },
  { value: 'Lucida Console', label: 'Lucida Console' },
  { value: 'Tahoma', label: 'Tahoma' },
  { value: 'Times New Roman', label: 'Times New Roman' },
  { value: 'Trebuchet MS', label: 'Trebuchet MS' },
  { value: 'Verdana', label: 'Verdana' },
];

export const SizeOptions = [
  { value: 32, label: '32px'},
  { value: 28, label: '28px'},
  { value: 24, label: '24px'},
  { value: 20, label: '20px'},
  { value: 18, label: '18px'},
  { value: 16, label: '16px'},
  { value: 14, label: '14px'},
  { value: 12, label: '12px'},
  { value: 10, label: '10px'},
  { value: 8,  label: '8px'},
];

export const StyleOptions = [
  { value: 'normal', label: 'Normal' },
  { value: 'bold', label: 'Bold' },
  { value: 'italic', label: 'Italic' },
];

const EditTextControl = ({canvasRef, overlayRef}) => {

  let current = DataManager.getCurrentDrawingTool();

  const textAreaRef = useRef(null);
  const textControlsRef = useRef(null);
  const textSizeRef = useRef();
  const textFontRef = useRef();
  const textStyleRef = useRef();


  const [selectedTextFont, setSelectedTextFont] = useState("Arial");
  const [selectedTextSize, setSelectedTextSize] = useState(20);
  const [selectedTextStyle, setSelectedTextStyle] = useState('normal');
  const [textControlVisible, setTextControlVisible] = useState(false);
  const [textColor, setTextColor] = useState(current.color);
  const [redrawFlag, setRedrawFlag] = useState(false);

  useEffect(() => {
    TextTool.setSize(selectedTextSize);
    TextTool.setFont(selectedTextFont);
    TextTool.setStyle(selectedTextStyle);
  },[]);

  useEffect(()=>{
    // console.log("SETTITNG CANVAS AND REF");
    if(!canvasRef.current || !overlayRef.current)
    {
      // console.log("CANVAS DOES NOT EXIST");
      return;
    }

    // console.log("REFRESHING EDIT TEXT!!");
    const canvas = canvasRef.current;
    const overlayCanvas = overlayRef.current;
    const textAreaControl = textAreaRef.current;
    const textSizeControl = textSizeRef.current;
    const textFontControl = textFontRef.current;
    const textStyleControl = textStyleRef.current;

    const context = canvas.getContext('2d');
    const overlayContext = overlayCanvas.getContext('2d');

    const handleOnKeyUp = (event) => {
      // console.log('TEXT TOOL ON KEY UP..');
      // console.log('TEXT AREA CONTROL IS DEF: ' + (!textAreaControl));
      const rect = textAreaRef.current.getBoundingClientRect();
      let controlData = {
        x: rect.left,
        y: rect.top,
        width: rect.width,
        height: rect.height,
        text: event.target.value
      };

      let size = TextTool.getSize();
      TextTool.setValue(event.target.value, controlData);
      TextTool.onKeyUp(event, canvas, context,
        overlayCanvas, overlayContext);
    }


    const handleOnTextChange = (event) => {
      // console.log('TEXT CHANGE ..');
      const rect = textAreaRef.current.getBoundingClientRect();
      let controlData = {
        x: rect.left,
        y: rect.top,
        width: rect.width,
        height: rect.height,
        text: event.target.value
      };
      let size = TextTool.getSize();
      TextTool.setValue(event.target.value, controlData);
    }

    const handleChangeTextFont = (event) => {
      TextTool.setFont(event.target.value);
      setSelectedTextFont(event.target.value);
    };

    const handleChangeTextSize = (event) => {
      TextTool.setSize(event.target.value);
      setSelectedTextSize(event.target.value);
    };

    const handleChangeTextStyle = (event) => {
      TextTool.setStyle(event.target.value);
      setSelectedTextStyle(event.target.value);
    };

    const updateTextAreaValue = (value) => {
      textAreaControl.value = value;
    }

    textFontControl.addEventListener('change', handleChangeTextFont, false);
    textSizeControl.addEventListener('change', handleChangeTextSize, false);
    textStyleControl.addEventListener('change', handleChangeTextStyle, false);
    textAreaControl.addEventListener('change', handleOnTextChange, false);
    textAreaControl.addEventListener('keyup', handleOnKeyUp, false);
    //textAreaControl.addEventListener('keypress', handleOnKeyUp, false);


    TextTool.setVisibleCallback(setTextControlVisible);
    TextTool.setRedrawCallback(setRedrawFlag);
    TextTool.setUpdateValueCallback(updateTextAreaValue);

    DataManager.addCurrentColorCallback("editTextBoxControl", setTextColor);

    //TODO: to textAreaControl need to add on mouse move likely too
    // possibly implement onMove in TextTool
  }, [canvasRef, overlayRef])


  //let visible = (current.tool === DrawingControls.Text && current.state === DrawingState.Writing);
  // console.log('KS: TextTool drawing and visible : ' + textControlVisible);
  // console.log('REDRAWING');
  //TODO: need to trigger this redraw onMouseUp / onMouseDown
  // possibly pass a callback for state setter into TextTool
  return (<div style={{
    position: "fixed",
    top: current.y - 36,
    left: current.x,
    visibility: !!textControlVisible ? 'visible' : 'collapse',
    backgroundColor: "transparent",
    zIndex: 1000
  }}>
    <div className="text-tools" ref={textControlsRef} style={{
      position: "fixed",
      top: current.y - 36,
      left: current.x,
      visibility: !!textControlVisible ? 'visible' : 'collapse',
      backgroundColor: "white",
      zIndex: 1000
    }}>
      <select value={selectedTextFont} ref={textFontRef} style={{
        position: "fixed",
        top: current.y - 33,
        left: current.x + 10,
        height: 30,
        borderStyle : "none",
        borderRight : "1px",
        borderRightStyle : "solid",
        borderRightColor : "grey"
      }}>
        {FontOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <select value={selectedTextSize} ref={textSizeRef} style={{
        position: "fixed",
        top: current.y - 33,
        left: current.x + 150,
        height: 30,
        borderStyle : "none",
        borderRight : "1px",
        borderRightStyle : "solid",
        borderRightColor : "grey"
      }}>
        {SizeOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <select value={selectedTextStyle} ref={textStyleRef} style={{
        position: "fixed",
        top: current.y - 33,
        left: current.x + 220,
        height: 30,
        borderStyle : "none",
      }}>
        {StyleOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
    <textarea autoFocus ref={textAreaRef}
          style={{
            position: "fixed",
            top: current.y,
            left: current.x,
            zIndex: 1000,
            backgroundColor: "transparent",
            fontFamily: selectedTextFont,
            fontSize: selectedTextSize + "px",
            fontStyle: selectedTextStyle,
            color : textColor,
            visibility: (current.tool === DrawingControls.Text && (current.state === DrawingState.Writing || current.state === DrawingState.Editing)) ? 'visible' : 'collapse'
          }} />
  </div>);
};

export default EditTextControl;
