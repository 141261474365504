import DataManager, {DrawingState, DrawingControls, ControlActions} from "./dataManager";
import jsPDF from 'jspdf';

class EventManager {
  constructor() {
     if (EventManager.instance){
      return EventManager.instance;
     }
     this.state = {
       loaded : false,
       emitSocketRef : null,
       maxUploadChunkBytes : 90000
     };
     EventManager.instance = this;
   }

   setEmitSocketReference(socketRef){
     this.state.emitSocketRef = socketRef;
   }

   onDrawingDataEmit(data){
     if(!this.state.emitSocketRef)
     {
       return;
     }

     let currentTool = DataManager.getCurrentDrawingTool();

     // console.log('>> SENDING EMIT DRAW << '
     //  + JSON.stringify(data) + "\n FINAL: " + data.final
     //  + "\n TOOL: " + currentTool.tool);

     this.state.emitSocketRef.emit(
      (currentTool.tool != DrawingControls.Selector)
        ? 'drawing' : 'updateobjects' , data);
   }

// Reactive Events
  onDrawingEventUnrestrictive(data, canvas, canvasContext,
    overlayCanvas, overlayContext,
    localUserCanvas, localUserCanvasContext){
      //console.log('ON DRAWING UNRESTRICTIVE!!! - ' + JSON.stringify(data));
      let sessionId = DataManager.getSessionId();
      let userId = DataManager.getUserId();

      let localOverlayContext = overlayContext;
      let localOverlayCanvas = overlayCanvas;

      if(data.control == DrawingControls.ClearOverlay){
        localOverlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
        return;
      }

      localOverlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      let controlInstance = DrawingControls.getToolInstance(data.control);
      controlInstance.drawEmittedData(data,
          canvas, canvasContext,
          localOverlayCanvas, localOverlayContext,
          userId, sessionId);

  }

  onDrawingEvent(data, canvas, canvasContext,
    overlayCanvas, overlayContext,
    localUserCanvas, localUserCanvasContext) {
    // console.log('ON DRAWING!!! - ' + JSON.stringify(data));
    let sessionId = DataManager.getSessionId();
    let userId = DataManager.getUserId();

    if(''+sessionId != ''+data.sessionId){
      return;
    }

    if((!!data.actingUserId && data.actingUserId == userId && data.userId == userId) ||
        (!data.actingUserId && data.userId == userId)){
      return;
    }

    let localOverlayContext = overlayContext;
    let localOverlayCanvas = overlayCanvas;
    if((data.userId != userId) ||
      (!!data.actingUserId && data.actingUserId != userId)){
      localOverlayCanvas = localUserCanvas;
      localOverlayContext = localUserCanvasContext;
    }

    if(data.control == DrawingControls.ClearOverlay){
      localOverlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      return;
    }


    //TODO: this condition may not matter,
    // but selected flag will matter
    if(data.control == DrawingControls.Selector){
      // console.log('ON DRAWING EVENT WITH CONTROL: SELECTOR');
    }

    // console.log('DRAW EMITTED control: ' +data.control+' userId: ' + data.userId);
    //Overlay should always be cleared prior to drawing emitted.
    localOverlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    let controlInstance = DrawingControls.getToolInstance(data.control);
    controlInstance.drawEmittedData(data,
        canvas, canvasContext,
        localOverlayCanvas, localOverlayContext,
        userId, sessionId);
  }


  onDrawInit(data,
    canvas, context,
    overlayCanvas, overlayContext,
    localUserCanvas, localUserCanvasContext){

    let sessionId = DataManager.getSessionId();
    let userId = DataManager.getUserId();

    // console.log('ON DRAW INIT DATA: ' + JSON.stringify(data));
    // console.log('ON DRAW INIT JSON OF OVERLAY');
    overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);

    if(!data || data.length == 0){
      // console.log('LEAVING DRAW INIT 1');
      return;
    }

    let serverSessionId = data[0].sessionId;
    if(/*!!this.state.loaded ||*/ ''+sessionId != ''+serverSessionId)
    {
      // console.log('LEAVING DRAW INIT 2');
      return;
    }

    let width = canvas.width;
    let height = canvas.height;
    let diagonal = Math.sqrt((width * width) + (height * height));

    // console.log('\n\n\n EMITTED DATA LENGTH: ' + data.length);

    const drawImage = (data, canvas, context,
      overlayCanvas, overlayContext,
      localUserCanvas, localUserCanvasContext) => {
        return new Promise((resolve, reject) => {
          let width = canvas.width;
          let height = canvas.height;
          //console.log('IMAGE ITEM: ' + JSON.stringify(item));
          if(!data || !data.image || data.image.length == 0){
            // console.log('No Image present!!');
            reject();
          }
          const image = new Image();
          image.src = data.image;
          image.onload = () => {
              // console.log('Started Drawing Image!!');
              context.drawImage(image,
                  data.x * width,
                  data.y * height,
                  data.w * width,
                  data.h * height
                );

              resolve();
          };
          image.onerror = () => {
            // console.log('Image draw error!');
            reject();
          };
      });
    };

    const drawData = async (data, canvas, context,
      overlayCanvas, overlayContext,
      localUserCanvas, localUserCanvasContext) => {
        for(let i = 0; i < data.length; i++){
          let item = data[i];
          item.final = true;
          let controlInstance = DrawingControls.getToolInstance(item.control);
          if(item.control == DrawingControls.Image){
            //console.log('IMAGE ITEM: ' + JSON.stringify(item));
            await drawImage(item, canvas, context,
              overlayCanvas, overlayContext,
              localUserCanvas, localUserCanvasContext);
          } else {
            controlInstance.drawEmittedData(item,
                      canvas, context,
                      overlayCanvas, overlayContext,
                      userId, sessionId);
          }
        }
    };

    drawData(data, canvas, context,
      overlayCanvas, overlayContext,
      localUserCanvas, localUserCanvasContext);

    //TODO: add loaded callback
    //setLoaded(true);
    this.state.loaded = true;
  }

  onRefreshEvent(data,
    canvas, context,
    overlayCanvas, overlayContext,
    localUserCanvas, localUserCanvasContext){

    let sessionId = DataManager.getSessionId();
    let userId = DataManager.getUserId();

    if(data.sessionId == sessionId){
      if(!!data.userId && data.userId != userId){
        localUserCanvasContext.clearRect(0, 0, localUserCanvas.width, localUserCanvas.height);
      } else {
        overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      }
      this.state.loaded = false;
      context.clearRect(0, 0, canvas.width, canvas.height);
      this.onDrawInit(data.data, canvas, context,
      overlayCanvas, overlayContext,
      localUserCanvas, localUserCanvasContext);
    }
  }

  onRefreshEmit(data){
    this.state.emitSocketRef.emit('refresh', data);
  }

  onClearCanvasEvent(data,
     canvas, context) {
    // console.log('CLEARING DATA EVENT');
    context.clearRect(0, 0, canvas.width, canvas.height);
  }

  onClearCanvasEmit(data){
    // console.log('CLEARING DATA EMIT');
    this.state.emitSocketRef.emit('clear', data);
  }

  onSelectionEvent(data){
  }

  onResize(data,
    canvas,
    canvasContext,
    overlayCanvas,
    overlayContext) {

    canvas.width = data.width;
    canvas.height = data.height;
    overlayCanvas.width = data.width;
    overlayCanvas.height = data.height;
    DataManager.setWindowSize(data.width, data.height);
  }

  onUndoAction(e, canvas, context,
    overlayCanvas, overlayContext,
    localUserCanvas, localUserCanvasContext){
    // console.log('UNDO ACTION IS CALLED');
    // //TODO: get this code working for undo button click
    let sessionId = DataManager.getSessionId();
    let userId = DataManager.getUserId();
    let hostname = DataManager.getCurrentHostName();

    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ sessionId : sessionId, userId : userId, numActions : 1 })
    };
    fetch('https://'+hostname+':8080/action/delete/actions', requestOptions).then((response) => {
        if(response.ok){
          return response.json();
        }
      }).then((data) => {
        let emitData = {
          sessionId : data.session,
          userId : userId,
          data : data.data
        }
        this.onRefreshEvent(emitData,
          canvas, context,
          overlayCanvas, overlayContext,
          localUserCanvas, localUserCanvasContext);
        this.state.emitSocketRef.emit('refresh', emitData);
      });
  }

  onSavePdfAction(e, canvas, context,
    overlayCanvas, overlayContext,
    localUserCanvas, localUserCanvasContext){
      let sessionId = DataManager.getSessionId();
      let canvasWidth = canvas.width;
      let canvasHeight = canvas.height;
      let pdfFile="session_"+sessionId+".pdf";

      let orientation = canvasWidth > canvasHeight ? 'l' : 'p';
      let pdf = new jsPDF(orientation, 'mm', 'a4'); // A4 size page of 210 x 297 mm
      let pageWidth = orientation === 'l' ? 297 : 210; // Switch dimensions based on orientation
      let pageHeight = orientation === 'l' ? 210 : 297;

      let scaleX = pageWidth / canvasWidth;
      let scaleY = pageHeight / canvasHeight;
      let scale = Math.min(scaleX, scaleY);

      let pdfWidth = canvasWidth * scale;
      let pdfHeight = canvasHeight * scale;

      let xOffset = (pageWidth - pdfWidth) / 2;
      let yOffset = (pageHeight - pdfHeight) / 2;

      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', xOffset, yOffset, pdfWidth, pdfHeight);
      pdf.save(pdfFile);
  }

  // onSavePdfAction(e, canvas, context,
  //   overlayCanvas, overlayContext,
  //   localUserCanvas, localUserCanvasContext){
  //     // console.log('ON SAVE PDF ACTION CALLED');
  //     let sessionId = DataManager.getSessionId();
  //     let imgData = canvas.toDataURL("image/png");
  //     let pdf = new jsPDF();
  //     let pdfFile="session_"+sessionId+".pdf";
  //
  //     // add the canvas image to the pdf document
  //     pdf.addImage(imgData, 'PNG', 0, 0);
  //
  //     // check if the canvas height is greater than the pdf page height
  //     // if yes, then add a new page and repeat the process
  //     let canvasWidth = canvas.width;
  //     let canvasHeight = canvas.height;
  //     let i = 1;
  //     let j = 0;
  //     while(canvasHeight > pdf.internal.pageSize.getHeight() ||
  //           canvasWidth > pdf.internal.pageSize.getWidth()) {
  //         while (canvasWidth > pdf.internal.pageSize.getWidth()) {
  //           pdf.addPage();
  //           pdf.addImage(imgData, 'PNG',
  //             -pdf.internal.pageSize.getWidth() * i,
  //             -pdf.internal.pageSize.getHeight() * j);
  //           canvasWidth -= pdf.internal.pageSize.getWidth();
  //           i++;
  //         }
  //
  //         if(canvasHeight <= pdf.internal.pageSize.getHeight())
  //         {
  //           break;
  //         }
  //         canvasHeight -= pdf.internal.pageSize.getHeight();
  //         j++;
  //         i = 0;
  //         canvasWidth = canvas.width;
  //     }
  //     // save the pdf document
  //     pdf.save(pdfFile);
  // }

  onDownloadAction(e, canvas, context,
    overlayCanvas, overlayContext,
    localUserCanvas, localUserCanvasContext){

    // console.log("On Download Called in EventManager");
    let sessionId = DataManager.getSessionId();
    let hostname = DataManager.getCurrentHostName();

    // Function to convert JSON object to string and trigger download
    const downloadJson = (data) => {
        const jsonString = JSON.stringify(data, null, 2); // Beautify the JSON string
        const blob = new Blob([jsonString], { type: 'application/json' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        let timestamp = new Date().getTime();
        link.href = href;
        link.download = "session_"+sessionId+"_"+timestamp+".colabt"; // Set the file name for the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href); // Clean up the object URL
    };

    fetch('https://'+hostname+':8080/action/download/session/' + sessionId).then((response) => {
      if(response.ok){
        return response.json();
      }
    }).then((data) => {
      //console.log('DOWNLOAD DATA: ' + JSON.stringify(data));
      downloadJson(data);
    });
  }


  onUploadChunks(sessionId, hostname, data, canvas, context,
    overlayCanvas, overlayContext,
    localUserCanvas, localUserCanvasContext){
    let dataStr = JSON.stringify(data);
    let sampleJSON = JSON.parse(dataStr);
    let dataLength = dataStr.length;
    let numChunks = Math.ceil(dataLength / this.state.maxUploadChunkBytes);

    let userId = DataManager.getUserId();

    // if(dataLength % this.state.maxUploadChunkBytes > 0) {
    //   numChunks += 1;
    // }
    //console.log("Upload Data Length: " + dataLength);

    let chunks = [];
    for(let i = 0; i < numChunks; i++) {
      let start = i * this.state.maxUploadChunkBytes;
      let end = ((dataLength - (i * this.state.maxUploadChunkBytes)) < 0)
        ? dataLength : (start + this.state.maxUploadChunkBytes);

      let chunk = dataStr.substring(start, end);
      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sessionId   : sessionId,
          userId      : userId,
          data        : chunk,
          id       : i + 1,
          totalLength : numChunks
         })
      };
      chunks.push(requestOptions);
      //console.log('\n\n\n\n\n\n\n\nCHUNK:  ' + JSON.stringify(requestOptions));
    }

    //Test chunked data
    // let jsonStrData = "";
    // for(let j = 0; j < chunks.length; j++){
    //   console.log(JSON.stringify(chunks));
    //   let tmp = JSON.parse(chunks[j].body);
    //   jsonStrData += tmp.data;
    // }
    // console.log("RESULT: " + JSON.stringify(jsonStrData));
    // let jsonData = JSON.parse(jsonStrData);
    // console.log("RESULT: " + JSON.stringify(jsonData));

    //Upload data in chunks
    for(let j = 0; j < chunks.length; j++){
      fetch('https://'+hostname+':8080/action/upload/chunk/session', chunks[j]).then((response) => {
          if(response.ok){
            return response.json();
          }
        }).then((data) => {
          //console.log('Upload done: ' + JSON.stringify(data));
          if(!data.data){
            return;
          }

          let emitData = {
            sessionId : data.session,
            userId : "", //This keeps user id undefined - to make sure that current user refreshes too
            data : data.data
          }
          this.onRefreshEvent(emitData,
            canvas, context,
            overlayCanvas, overlayContext,
            localUserCanvas, localUserCanvasContext);
          this.state.emitSocketRef.emit('refresh', emitData);
      });
    }

  }

  onUploadAction(data, canvas, context,
    overlayCanvas, overlayContext,
    localUserCanvas, localUserCanvasContext){

      //console.log('On Upload Called in EventManager: ' + JSON.stringify(data));
      //TODO: implement the call to "/action/upload/session/"
      let sessionId = DataManager.getSessionId();
      let hostname = DataManager.getCurrentHostName();

      let dataLength = JSON.stringify(data.data).length;
      if(dataLength > this.state.maxUploadChunkBytes){
        this.onUploadChunks(sessionId, hostname, data.data,
          canvas, context,
            overlayCanvas, overlayContext,
            localUserCanvas, localUserCanvasContext);
        return
      } else {
        // console.log('Data can go as single chunk: ' + dataLength);
      }

      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sessionId   : sessionId,
          data        : data.data,
          chunk       : 1,
          totalChunks : 1
         })
      };

      fetch('https://'+hostname+':8080/action/upload/session', requestOptions).then((response) => {
          if(response.ok){
            return response.json();
          }
        }).then((data) => {
          //console.log('Upload done: ' + JSON.stringify(data));
          let emitData = {
            sessionId : data.session,
            userId : "", //This keeps user id undefined - to make sure that current user refreshes too
            data : data.data
          }
          this.onRefreshEvent(emitData,
            canvas, context,
            overlayCanvas, overlayContext,
            localUserCanvas, localUserCanvasContext);
          this.state.emitSocketRef.emit('refresh', emitData);
      });
  }

  onLeaveWebsiteAction(){
    let sessionId = DataManager.getSessionId();
    let userId = DataManager.getUserId();
    let hostname = DataManager.getCurrentHostName();
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ sessionId : sessionId, userId : userId })
    };

    fetch('https://'+hostname+':8080/action/leave/actions', requestOptions).then((response) => {
    //fetch('http://'+hostname+':8080/action/delete/session', requestOptions).then((response) => {
        if(response.ok){
          return response.json();
        }
      }).then((data) => {
        //TODO: possibly notify for Refresh
        // console.log('User Left');
        //this.state.emitSocketRef.emit('updateusers', { user : userId, sessionId : sessionId });
      });
  }

// User Actions (Web)
  onMouseDown(e,
      canvas,
      canvasContext,
      overlayCanvas,
      overlayCanvasContext){
    let toolType = DataManager.getCurrentDrawingTool();
    let toolInstance = DrawingControls.getToolInstance(toolType.tool);
    toolInstance.onMouseDown(e, canvas, canvasContext,
       overlayCanvas, overlayCanvasContext);
  }

  onMouseMove(e,
      canvas,
      canvasContext,
      overlayCanvas,
      overlayCanvasContext){
    let toolType = DataManager.getCurrentDrawingTool();
    let toolInstance = DrawingControls.getToolInstance(toolType.tool);

    toolInstance.onMouseMove(e, canvas, canvasContext,
       overlayCanvas, overlayCanvasContext);
  }

  onMouseUp(e,
      canvas,
      canvasContext,
      overlayCanvas,
      overlayCanvasContext){
    let toolType = DataManager.getCurrentDrawingTool();
    let toolInstance = DrawingControls.getToolInstance(toolType.tool);
    toolInstance.onMouseUp(e, canvas, canvasContext,
       overlayCanvas, overlayCanvasContext);
  }

  onKeyUp(e,
      canvas,
      canvasContext,
      overlayCanvas,
      overlayCanvasContext){
    let toolType = DataManager.getCurrentDrawingTool();
    let toolInstance = DrawingControls.getToolInstance(toolType.tool);
    toolInstance.onKeyUp(e, canvas, canvasContext,
       overlayCanvas, overlayCanvasContext);
  }

//User Actions (Device)


}

const instance = new EventManager();
Object.freeze(instance);
export default instance;
