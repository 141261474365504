import React, { useRef, useEffect, useState} from 'react';
import DataManager from "../data/dataManager";

import "../../styles/board.css";

const ColorsToolBar = () => {
  const [currentColor, setCurrentColor] = useState("black");
  const [windowDimensions, setWindowDimensions] = useState({
    width : window.innerWidth,
    height : window.innerHeight
  });
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayNotificationBar, setDisplayNotificationBar] = useState(false);
  const [displaySaveMenu, setDisplaySaveMenu] = useState(false);

  useEffect(() => {
    DataManager.addCurrentColorCallback("colorsToolBar", setCurrentColor);
    DataManager.addWindowResizeCallback("colorsToolBar", setWindowDimensions);
    DataManager.addDismissMobileMenuCallback("colorsToolBar",
      () => {
        setDisplayMenu(false);
      });
    DataManager.addNotificationTextCallback("colorsToolBar", (text) => {
      setDisplayNotificationBar(!!text && text.length > 0);
    });
    DataManager.addDisplaySaveMenuCallback("colorsToolBar", setDisplaySaveMenu);
  }, []);

  useEffect(() => {
    if(windowDimensions.width <= 950){
      return;
    }
    const colors = document.getElementsByClassName('color');
    const onColorUpdate = (e) => {
      let color = e.target.className.split(' ')[1];
      DataManager.setCurrentColor(color);
    };

    for (let i = 0; i < colors.length; i++) {
      colors[i].addEventListener('click', onColorUpdate, false);
    }
  },[windowDimensions]);

  useEffect(() => {
    if(!displayMenu){
      return;
    }

    const colors = document.getElementsByClassName('color');
    const onColorUpdate = (e) => {
      let color = e.target.className.split(' ')[1];
      DataManager.setCurrentColor(color);
      setDisplayMenu(false);
    };

    for (let i = 0; i < colors.length; i++) {
      if(colors[i].className.split(' ').length > 2){
        continue;
      }
      colors[i].addEventListener('click', onColorUpdate, false);
    }
  }, [displayMenu]);

  let menuControl = (displayMenu && windowDimensions.width <= 950) ? (
    <div  className="colors overlay" style={{
      width: "170px",
      top: "65px", //"50px",
      zIndex: 5000
    }}>
        <div className="color white" />
        <div className="color black" />
        <div className="color gray" />
        <div className="color red" />
        <div className="color orange" />
        <div className="color yellow" />
        <div className="color green" />
        <div className="color blue" />
        <div className="color pink" />
        <div className="color purple" />
        <div className="color brown" />
      </div>) : null;

  let top = 110; //100;
  if(!!displayNotificationBar){
    top += 60; //50;
  }

  if(!!displaySaveMenu){
    top += 50; //40;
  }

  let controlToDisplay = (windowDimensions.width > 950)
    ? (<div  className="colors overlay" style={{
          top : top+"px",  //!!displayNotificationBar ? "150px" : "100px",
          zIndex: 5000
        }}>
          <div className="color white" />
          <div className="color black" />
          <div className="color gray" />
          <div className="color red" />
          <div className="color orange" />
          <div className="color yellow" />
          <div className="color green" />
          <div className="color blue" />
          <div className="color pink" />
          <div className="color purple" />
          <div className="color brown" />
        </div>)
    : (<div className={"color overlay " + currentColor}
        style={{
          position : 'fixed',
          //left: '77px',
          top: '26px', //'11px',
          width: '30px',
          height: '30px',
          zIndex: 5000
        }} onClick={() => {
            setDisplayMenu(!displayMenu);
            DataManager.dismissAllMobileMenusExceptKey("colorsToolBar");
          }} />);
  return (<div>{controlToDisplay}{menuControl}</div>);
};

export default ColorsToolBar;
