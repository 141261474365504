import BaseTool from "./base/baseTool"
import DataManager, {DrawingState, DrawingControls} from "../dataManager"
import EventManager from "../eventManager";

class EraserTool extends BaseTool {
  constructor(){
    super();
    EraserTool.instance = this;
  }
  onInit(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
  }

  onComplete(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
  }

  onMouseDown(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (current.state != DrawingState.None) { return; }

    current.state = DrawingState.Drawing;
    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;

    let info = {
      x : current.x,
      y : current.y,
      radius : 15,
      overlayWidth : overlayCanvas.width,
      overlayHeight : overlayCanvas.height
    };

    let style = {
      color : current.color,
      outline : true,
      outlineCanvasContext : overlayCanvasContext
    };

    this.draw(info, style, canvas, canvasContext, true, true, sessionId);

    DataManager.setCurrentDrawingTool(current);
  }

  onMouseMove(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (current.state != DrawingState.Drawing) { return; }

    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;

    let info = {
      x : current.x,
      y : current.y,
      radius : 15,
      overlayWidth : overlayCanvas.width,
      overlayHeight : overlayCanvas.height
    };

    let style = {
      color : current.color,
      outline : true,
      outlineCanvasContext : overlayCanvasContext
    };

    this.draw(info, style, canvas, canvasContext, true, true, sessionId);

    DataManager.setCurrentDrawingTool(current);
    DataManager.incrementCurrentObjectId();
  }

  onMouseUp(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (current.state != DrawingState.Drawing) { return; }

    overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    //emitClearOverlay(sessionId);
    current.x = e.clientX || e.changedTouches[0].clientX;
    current.y = e.clientY || e.changedTouches[0].clientY;
    current.state = DrawingState.None;



    DataManager.setCurrentDrawingTool(current);
    DataManager.incrementCurrentObjectId();
  }

  drawEmittedData(data,
    canvas, context,
    overlayCanvas, overlayContext,
    userId, sessionId){
    // console.log('ERASER ON EMITTED DATA: ' + JSON.stringify(data));

    let width = canvas.width;
    let height = canvas.height;
    let diagonal = Math.sqrt((width*width)+(height*height));

    let info = {
      x : data.x * width,
      y : data.y * height,
      radius : data.r * diagonal,
      overlayWidth : overlayCanvas.width,
      overlayHeight : overlayCanvas.height
    };

    let style = {
      color : data.color,
      outline : false,
      outlineCanvasContext : overlayContext
    };

    this.draw(info, style, canvas,
      (!!data.final && !data.selected) ? context : overlayContext,
      data.final, false, sessionId);
  }

  draw(info, style, canvas, context, isFinal, emit, sessionId){
    const {x, y, radius, overlayWidth, overlayHeight} = info;
    const {color = 'black', outline, outlineCanvasContext} = style;

    if(!!outline){
      outlineCanvasContext.clearRect(0, 0, overlayWidth, overlayHeight);
      outlineCanvasContext.beginPath();
      outlineCanvasContext.arc(x, y, radius, 0, 2 * Math.PI);
      outlineCanvasContext.strokeStyle = color;
      outlineCanvasContext.lineWidth = 3;
      outlineCanvasContext.stroke();
    }

    // console.log('DRAWING ERASER ON EMITTED DATA: ' + JSON.stringify(info));
    //let canvasContext = canvas.getContext('2d');
    context.beginPath();
    context.arc(x, y, radius, 0, 2 * Math.PI);
    context.fillStyle = 'white';
    context.fill();



    if (!emit) { return; }

    const w = canvas.width;
    const h = canvas.height;
    const rd = Math.sqrt((w * w) + (h * h));
    let userId = DataManager.getUserId();
    let drawObjectId = DataManager.getCurrentObjectId();
    let current = DataManager.getCurrentDrawingTool();

    EventManager.onDrawingDataEmit({
      sessionId: sessionId,
      userId: userId,   //userID will be set only when emit is set to true, meaning user drew the line
      actingUserId: userId,
      objectId: drawObjectId,
      control: DrawingControls.Eraser,
      final: isFinal,
      selected: false,
      drawingState: current.drawingState,
      x: x / w,
      y: y / h,
      r: radius / rd,
      color
    });
  }
}

const instance = new EraserTool();
Object.freeze(instance);
export default instance;
//export default EraserTool;
