import BaseTool from "./base/baseTool"
import DataManager, {DrawingState, DrawingControls} from "../dataManager"
import EventManager from "../eventManager";

class RectTool extends BaseTool {
  constructor(){
    super();
    RectTool.instance = this;
  }

  onInit(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
  }

  onComplete(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
  }

  onMouseDown(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    if (current.state != DrawingState.None) { return; }

    current.state = DrawingState.Drawing;

    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;
    current.maxWidth = 0;
    current.maxHeight = 0;

    DataManager.setCurrentDrawingTool(current);
  }

  onMouseMove(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (current.state != DrawingState.Drawing) { return; }

    let width = (e.clientX || e.touches[0].clientX) - current.x;
    let height = (e.clientY || e.touches[0].clientY) - current.y;
    current.maxWidth = (current.maxWidth < width) ? width : current.maxWidth;
    current.maxHeight = (current.maxHeight < height) ? height : current.maxHeight;

    let info = {
      x : current.x,
      y : current.y,
      w : width,
      h : height
    };

    let style = {
      borderColor : current.color,
      borderWidth : 1
    };

    overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    this.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);

    DataManager.setCurrentDrawingTool(current);
  }

  onMouseUp(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (current.state != DrawingState.Drawing) { return; }

    let width = (e.clientX || e.changedTouches[0].clientX) - current.x;
    let height = (e.clientY || e.changedTouches[0].clientY) - current.y;
    current.maxWidth = (current.maxWidth < width) ? width : current.maxWidth;
    current.maxHeight = (current.maxHeight < height) ? height : current.maxHeight;
    current.state = DrawingState.None;

    let info = {
      x : current.x,
      y : current.y,
      w : width,
      h : height,
      state: current.state
    };

    let style = {
      borderColor : current.color,
      borderWidth : 1
    };

    overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    this.draw(info, style, canvas, canvasContext, true, true, sessionId);

    current.x = e.clientX || e.changedTouches[0].clientX;
    current.y = e.clientY || e.changedTouches[0].clientY;


    DataManager.setCurrentDrawingTool(current);
    DataManager.incrementCurrentObjectId();
  }

  drawEmittedData(data,
    canvas, context,
    overlayCanvas, overlayContext,
    userId, sessionId){
      // console.log('RECT EMITTED DRAW FINAL :' + data.final);

      let width = canvas.width;
      let height = canvas.height;

      let info = {
        x : data.x * width,
        y : data.y * height,
        w : data.w * width,
        h : data.h * height,
        state: data.drawingState
      };

      let style = {
        borderColor : data.color,
        borderWidth : 1
      };

      //overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      this.draw(info, style, canvas,
        (!!data.final && !data.selected) ? context : overlayContext, data.final, false, sessionId);
  }

  draw(info, style, canvas, context, isFinal, emit, sessionId){
    const { x, y, w, h, state } = info;
    const { borderColor = 'black', borderWidth = 1 } = style;

    context.beginPath();
    context.strokeStyle = borderColor;
    context.lineWidth = borderWidth;
    context.rect(x, y, w, h);
    context.stroke();

    if (!emit) { return; }

    let width = canvas.width;
    let height = canvas.height;
    let userId = DataManager.getUserId();
    let drawObjectId = DataManager.getCurrentObjectId();
    //let current = DataManager.getCurrentDrawingTool();

    // console.log('CALLING RECT EMITFINAL :' + isFinal + ", UserId: " + userId);
    EventManager.onDrawingDataEmit({
      sessionId: sessionId,
      userId: userId,
      actingUserId: userId,
      objectId: drawObjectId,
      control: DrawingControls.Rect,
      final: isFinal,
      selected: false,
      drawingState: state,
      x: x / width,
      y: y / height,
      w: w / width,
      h: h / height,
      color : borderColor,
    });
  }
}

const instance = new RectTool();
Object.freeze(instance);
export default instance;
//export default RectTool;
