import BaseTool from "./base/baseTool"
import DataManager, {DrawingState, DrawingControls} from "../dataManager"
import EventManager from "../eventManager";

class FilledRectTool extends BaseTool {
  constructor(){
    super();
    FilledRectTool.instance = this;
  }

  onInit(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
  }

  onComplete(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
  }

  onMouseDown(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    if (current.state != DrawingState.None) { return; }
    current.state = DrawingState.Drawing;

    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;
    current.maxWidth = 0;
    current.maxHeight = 0;

    DataManager.setCurrentDrawingTool(current);
  }

  onMouseMove(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (current.state != DrawingState.Drawing) { return; }

    let width = (e.clientX || e.touches[0].clientX) - current.x;
    let height = (e.clientY || e.touches[0].clientY) - current.y;
    current.maxWidth = (current.maxWidth < width) ? width : current.maxWidth;
    current.maxHeight = (current.maxHeight < height) ? height : current.maxHeight;

    let info = {
      x : current.x,
      y : current.y,
      w : width,
      h : height
    };

    let style = {
      backgroundColor : current.color,
      borderWidth : 1
    };

    overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    this.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);

    DataManager.setCurrentDrawingTool(current);
  }

  onMouseUp(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (current.state != DrawingState.Drawing) { return; }

    let width = (e.clientX || e.changedTouches[0].clientX) - current.x;
    let height = (e.clientY || e.changedTouches[0].clientY) - current.y;
    current.maxWidth = (current.maxWidth < width) ? width : current.maxWidth;
    current.maxHeight = (current.maxHeight < height) ? height : current.maxHeight;

    let info = {
      x : current.x,
      y : current.y,
      w : width,
      h : height
    };

    let style = {
      backgroundColor : current.color,
      borderWidth : 1
    };

    overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    this.draw(info, style, canvas, canvasContext, true, true, sessionId);

    current.x = e.clientX || e.changedTouches[0].clientX;
    current.y = e.clientY || e.changedTouches[0].clientY;
    current.state = DrawingState.None;

    DataManager.setCurrentDrawingTool(current);
    DataManager.incrementCurrentObjectId();
  }

  drawEmittedData(data,
    canvas, context,
    overlayCanvas, overlayContext,
    userId, sessionId){

    let width = canvas.width;
    let height = canvas.height;

    let info = {
      x : data.x * width,
      y : data.y * height,
      w : data.w * width,
      h : data.h * height
    };

    let style = {
      backgroundColor : data.color,
      borderWidth : 1
    };

    //overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    this.draw(info, style, canvas, (!!data.final && !data.selected) ? context : overlayContext, data.final, false, sessionId);
  }

  draw(info, style, canvas, context, isFinal, emit, sessionId){
    const { x, y, w, h } = info;
    const { backgroundColor = 'black' } = style;

    context.beginPath();
    context.fillStyle = backgroundColor;
    context.fillRect(x, y, w, h);

    if (!emit) { return; }

    let width = canvas.width;
    let height = canvas.height;
    let userId = DataManager.getUserId();
    let drawObjectId = DataManager.getCurrentObjectId();
    let current = DataManager.getCurrentDrawingTool();

    EventManager.onDrawingDataEmit({
      sessionId: sessionId,
      userId: userId,   //userID will be set only when emit is set to true, meaning user drew the line
      actingUserId: userId,
      objectId: drawObjectId,
      control: DrawingControls.FilledRect,
      final: isFinal,
      selected: false,
      drawingState: current.drawingState,
      x: x / width,
      y: y / height,
      w: w / width,
      h: h / height,
      color : backgroundColor,
    });
  }
}

const instance = new FilledRectTool();
Object.freeze(instance);
export default instance;
//export default FilledRectTool;
