import BaseTool from "./base/baseTool"
import DataManager, {DrawingState, DrawingControls} from "../dataManager"
import EventManager from "../eventManager";

//TODO: possibly might need to make the tools as singleton
//TODO: current problem the drawing on the canvasContext does not show.
//TODO: could be a refresh problem too..


class LineTool extends BaseTool {
  constructor(){
    super();
    LineTool.instance = this;
  }

  onInit(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
  }

  onComplete(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
  }

  onMouseDown(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    if (current.state != DrawingState.None) { return; }

    current.state = DrawingState.Drawing;

    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;
    current.maxWidth = 0;
    current.maxHeight = 0;

    DataManager.setCurrentDrawingTool(current);
  }

  onMouseMove(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (current.state != DrawingState.Drawing) { return; }

    let info = {
      x : current.x,
      y : current.y,
      x1 : e.clientX || e.touches[0].clientX,
      y1 : e.clientY || e.touches[0].clientY
    };

    let style = {
      color : current.color
    };

    //let canvas = super.getCanvas();

    this.draw(info, style, canvas, canvasContext, true, true, sessionId);

    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;

    DataManager.setCurrentDrawingTool(current);
    DataManager.incrementCurrentObjectId();
  }

  onMouseUp(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (current.state != DrawingState.Drawing) { return; }

    current.state = DrawingState.None;

    let info = {
      x : current.x,
      y : current.y,
      x1 : e.clientX || e.changedTouches[0].clientX,
      y1 : e.clientY || e.changedTouches[0].clientY,
    };

    let style = {
      color : current.color
    };

    this.draw(info, style, canvas, canvasContext, true, true, sessionId);

    DataManager.setCurrentDrawingTool(current);
    DataManager.incrementCurrentObjectId();
  }

  drawEmittedData(data,
    canvas, context,
    overlayCanvas, overlayContext,
    userId, sessionId){
    //Should draw regardless if userId is equal or not
    //for Init Data.. But for onDrawEvents userId
    //should be filtered in EventManager or a level up.

    // console.log('EMITTED LINE: ' + JSON.stringify(data));

    let width = canvas.width;
    let height = canvas.height;

    let info = {
      x : data.x0 * width,
      y : data.y0 * height,
      x1 : data.x1 * width,
      y1 : data.y1 * height,
    };

    let style = {
      color : data.color
    };

    //this.draw(info, style, canvas, context, data.final, false, sessionId);
    // console.log('Drawing Emitted Line: ' + JSON.stringify(info) + ", isFinal: " + data.final);
    this.draw(info, style, canvas,
      (!!data.final && !data.selected) ? context : overlayContext,
      data.final, false, sessionId);
  }

  draw(info, style, canvas, context, isFinal, emit, sessionId){
    const { x, y, x1, y1 } = info;
    const { color = 'black' } = style;
    //console.log('Drawing Line: ' + JSON.stringify(info) + ", isFinal: " + isFinal);
    //console.log('Drawing Line: ' + JSON.stringify(style));

    // console.log('canvas w: ' + canvas.width + ', h: '+canvas.height);
    context.beginPath();
    //it is important to subtract the same amount
    //of pixels from the height, as css file does
    context.moveTo(x, y);
    context.lineTo(x1, y1);
    context.strokeStyle = color;
    context.lineWidth = 2;
    context.stroke();
    context.closePath();

    if (!emit) { return; }

    let w = canvas.width;
    let h = canvas.height;
    let userId = DataManager.getUserId();
    let drawObjectId = DataManager.getCurrentObjectId();
    let current = DataManager.getCurrentDrawingTool();

    // console.log('About to Emit in Line draw');
    EventManager.onDrawingDataEmit({
      sessionId: sessionId,
      userId: userId,   //userID will be set only when emit is set to true, meaning user drew the line
      actingUserId: userId,
      objectId: drawObjectId,
      control: DrawingControls.Line,
      final: isFinal, //true,
      selected: false,
      drawingState: current.state,
      x0: x / w,
      y0: y / h,
      x1: x1 / w,
      y1: y1 / h,
      color,
    });
  }
}

const instance = new LineTool();
Object.freeze(instance);
export default instance;
//export default LineTool;
