import React, { useRef, useEffect, useState} from 'react';
import DataManager from "../data/dataManager";

//import "../../styles/board.css";
import "../../styles/notification_bar.css";

const NotificationBar = () => {
  const [notificationText, setNotificationText] = useState("");//useState("");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // console.log('Loaded Notification BAR!!!!: ' + notificationText);
    DataManager.addNotificationTextCallback('notificationBar', setNotificationText);
    DataManager.setIsNotificationDisplayedCallback(() => { return visible; });
  }, []);

  useEffect(() => {
    setVisible(notificationText.length > 0);
  }, [notificationText]);

  useEffect(() => {
    DataManager.setIsNotificationDisplayedCallback(() => { return visible; });
  }, [visible]);


  return (<div className="notificationbar" style={{
    display : (!visible) ? 'none' : 'flex',
    width: '100%',
    zIndex: 5000
  }}>{notificationText}</div>);
};

export default NotificationBar;
