import DataManager, {DrawingState, DrawingControls} from "../../dataManager";

class BaseTool {
  constructor(){
  }

  onInit(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
      throw "Not implemented";
  }

  onComplete(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
      throw "Not implemented";
  }

  onMouseDown(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    throw "Not implemented";
  }

  onMouseMove(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    throw "Not implemented";
  }

  onMouseUp(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    throw "Not implemented";
  }

  onKeyUp (e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    throw "Not implemented";
  }

  onEditSelection(data,
    canvas, context,
    overlayCanvas, overlayContext){
    throw "Not implemented";
  }

  drawEmittedData(data,
    canvas, context,
    overlayCanvas, overlayContext,
    userId, sessionId){
    throw "Not implemented";
  }

  draw(info, style, canvas, context, isFinal, emit, sessionId){
    throw "Not implemented";
  }
}

export default BaseTool;
