import BaseTool from "./base/baseTool"
import DataManager, {DrawingState, DrawingControls, ColorRGB} from "../dataManager"
import EventManager from "../eventManager";

class PointerTool extends BaseTool {
  constructor(){
    super();
    PointerTool.instance = this;
  }

  onInit(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
      // console.log('POINTER TOOL INIT IS CALLED()');
      //this.state.intervalId = -1;


      //TODO: start recurring fading thread

      let current = DataManager.getCurrentDrawingTool(); //this will be old tool
      let sessionId = DataManager.getSessionId();
      current.fadingLineList = [];
      if(current.intervalId > 0){
        clearInterval(current.intervalId);
      }
      current.intervalId = -1;

      let info = {
        x : (canvas.width - 25),
        y : 180
      };

      let style = {
        color : current.color,
        fadingLineList : current.fadingLineList
      };


      overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      this.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);

      DataManager.setCurrentDrawingTool(current);
  }

  onComplete(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){

      // console.log('POINTER TOOL COMPLETE IS CALLED()');

      //TODO: stop recurring fading thread

      let sessionId = DataManager.getSessionId();
      let userId = DataManager.getUserId();

      overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);

      let data = {
        sessionId : sessionId,
        userId : userId
      };

      EventManager.onClearCanvasEmit(data);
  }

  onMouseDown(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
      let current = DataManager.getCurrentDrawingTool();
      let sessionId = DataManager.getSessionId();

      //console.log('KS: Pointer tool ON MOUSE DOWN: ' + JSON.stringify(current));

      if (current.state != DrawingState.None) { return; }

      current.state = DrawingState.Drawing;

      current.x = e.clientX || e.touches[0].clientX;
      current.y = e.clientY || e.touches[0].clientY;
      current.maxWidth = 0;
      current.maxHeight = 0;

      current.fadingLineList = [];
      if(current.intervalId > 0){
        clearInterval(current.intervalId);
      }
      current.intervalId = -1;

      let info = {
        x : current.x,
        y : current.y
      };

      let style = {
        color : current.color,
        fadingLineList : current.fadingLineList
      };

      overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      this.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);

     DataManager.setCurrentDrawingTool(current);
  }

  onMouseMove(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    //TODO: when onInit and onComplete will be enable comment line below
    //if (current.state != DrawingState.Drawing) { return; }

    let oldX = current.x;
    let oldY = current.y;

    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;

    //Add fading lines after mouse is pressed
    //TODO: may need to resetInterval on mouse move,
    if(current.state == DrawingState.Drawing)
    {
      current.fadingLineList.push({
        x0 : oldX,
        y0 : oldY,
        x1 : current.x,
        y1 : current.y,
        alpha : 1
      });

      for(let idx = 0; idx < current.fadingLineList.length; idx++){
        current.fadingLineList[idx].alpha -= .03;
        if(current.fadingLineList[idx].alpha <= 0){
          current.fadingLineList.splice(idx, 1);
          idx--;
        }
      }
    }

    let info = {
      x : current.x,
      y : current.y
    };

    let style = {
      color : current.color,
      fadingLineList : current.fadingLineList
    };

    //console.log('Fading Line: ' + JSON.stringify(style.fadingLineList));

    overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    this.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);

    DataManager.setCurrentDrawingTool(current);
  }

  onMouseUp(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
      let current = DataManager.getCurrentDrawingTool();
      let sessionId = DataManager.getSessionId();
      let userId = DataManager.getUserId();
      if (current.state != DrawingState.Drawing) { return; }

      overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      let data = {
        sessionId : sessionId,
        userId : userId
      };

      EventManager.onClearCanvasEmit(data);

      current.state = DrawingState.None;
      current.fadingLineList = [];

      //We trigger fading on timer since we're no longer moving
      // adding new lines
      // console.log('ABOUT TO SET FADE INTERVAL..: '+ current.intervalId);
      // if(current.intervalId == -1) {
      //   //setInterval(myFunction.bind(null, 'Hello', 'World'), 10000);
      //   current.intervalId = setInterval(
      //     this.refreshFadingLine.bind(null, canvas, canvasContext,
      //         overlayCanvas, overlayCanvasContext, true), 1000); // 10000 ms = 10 sec
      // }

      let info = {
        x : current.x,
        y : current.y
      };

      let style = {
        color : current.color,
        fadingLineList : current.fadingLineList
      };

      overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      this.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);
      DataManager.setCurrentDrawingTool(current);
  }

  drawEmittedData(data,
    canvas, context,
    overlayCanvas, overlayContext,
    userId, sessionId){

    let width = canvas.width;
    let height = canvas.height;

    let info = {
      x : data.x * width,
      y : data.y * height
    };

    for(let idx = 0; idx < data.fadingLineList.length; idx++){
      data.fadingLineList[idx].x0 = data.fadingLineList[idx].x0 * width;
      data.fadingLineList[idx].y0 = data.fadingLineList[idx].y0 * height;
      data.fadingLineList[idx].x1 = data.fadingLineList[idx].x1 * width;
      data.fadingLineList[idx].y1 = data.fadingLineList[idx].y1 * height;
    }

    let style = {
      color : data.color,
      fadingLineList : data.fadingLineList
    };

    //overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    this.draw(info, style, canvas, overlayContext, data.final, false, sessionId);
  }

  drawPointerShape(context, color, x, y){
    //draw small filled circle
    context.beginPath();
    context.arc(x, y, 5, 0, 2 * Math.PI);
    context.fillStyle = color;
    context.fill();

    //TODO: draw fading line... it might have to be
    // and object where everytime useEffect for all is called line fades
    //... or will keep track of all fading lines and fade each accordingly on
    // mouse move.... also try using timer.. or see what animatedFrame is about..
  }

  drawFadingLine(context, color, fadingLineList) {
    let colorRgb = ColorRGB[color];
    for(let idx=0; idx < fadingLineList.length; idx++){
      let lineObj = fadingLineList[idx];
      context.beginPath();
      context.moveTo(lineObj.x0, lineObj.y0);
      context.lineTo(lineObj.x1, lineObj.y1);
      context.strokeStyle = 'rgba('+colorRgb.r +', '+colorRgb.g+', '+colorRgb.b+', '+lineObj.alpha+')';
      context.lineJoin = 'round'; // Creates a rounded corner when two lines meet
      //context.lineCap = 'round';
      context.lineWidth = 10;
      context.stroke();
      context.closePath();
    }
  }

  refreshFadingLine(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext, clearOverlay){
    //TODO: update fading line
    // may just need to use this.state for storing the fading list
    // the timer should be used only after mouseUp
    let current = DataManager.getCurrentDrawingTool();


    if(current.fadingLineList.length == 0){
      // console.log('FADING LINE EXISITNG!!!');
      clearInterval(current.intervalId);
      current.intervalId = -1;
      return;
    }

    for(let idx = 0; idx < current.fadingLineList.length; idx++){
      current.fadingLineList[idx].alpha -= .3;
      if(current.fadingLineList[idx].alpha <= 0){
        current.fadingLineList.splice(idx, 1);
        idx--;
      }
    }

    if(!!clearOverlay) {
      overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    }
    this.drawFadingLine(overlayCanvasContext, 'blue', current.fadingLineList);


    //TODO: fix this may conflict with coordinate changes in other
    // mouse movements
    DataManager.setCurrentDrawingTool(current);

    //this.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);
    // console.log('Fading list: ' + JSON.stringify(current.fadingLineList));

  }

  draw(info, style, canvas, context, isFinal, emit, sessionId){
    const { x, y } = info;
    const { color = 'black', fadingLineList = []} = style;

    this.drawPointerShape(context, color, x, y);

    if(fadingLineList.length > 0){
      this.drawFadingLine(context, color, fadingLineList);
    }


    if (!emit) { return; }

    let width = canvas.width;
    let height = canvas.height;
    let userId = DataManager.getUserId();
    let drawObjectId = DataManager.getCurrentObjectId();
    let current = DataManager.getCurrentDrawingTool();

    let notmalizedLineList = []
    for(let idx = 0; idx < fadingLineList.length; idx++){
      notmalizedLineList.push({
        x0 : fadingLineList[idx].x0 / width,
        y0 : fadingLineList[idx].y0 / height,
        x1 : fadingLineList[idx].x1 / width,
        y1 : fadingLineList[idx].y1 / height,
        alpha : fadingLineList[idx].alpha
      });
    }

    EventManager.onDrawingDataEmit({
      sessionId: sessionId,
      userId: userId,   //userID will be set only when emit is set to true, meaning user drew the line
      actingUserId: userId,
      objectId: drawObjectId,
      control: DrawingControls.Pointer,
      final: false,
      selected: false,
      drawingState: current.drawingState,
      x: x / width,
      y: y / height,
      color : color,
      //TODO: need to adjust x0, y0, x1, y1 for height and width in
      //every object in the list prior to passing to remote client
      fadingLineList : notmalizedLineList,
    });
  }
}

const instance = new PointerTool();
Object.freeze(instance);
export default instance;
//export default PointerTool;
