import BaseTool from "./base/baseTool"
import DataManager, {DrawingState, DrawingControls} from "../dataManager"
import EventManager from "../eventManager";

class StraightLineTool extends BaseTool {
  constructor(){
    super();
    StraightLineTool.instance = this;
  }

  onInit(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
  }

  onComplete(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
  }

  onMouseDown(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    if (current.state != DrawingState.None) { return; }

    current.state = DrawingState.Drawing;

    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;
    current.maxWidth = 0;
    current.maxHeight = 0;

    DataManager.setCurrentDrawingTool(current);
  }

  onMouseMove(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (current.state != DrawingState.Drawing) { return; }

    let endX = e.clientX || e.touches[0].clientX;
    let endY = e.clientY || e.touches[0].clientY;

    let info = {
      x0 : current.x,
      y0 : current.y,
      x1 : endX,
      y1 : endY
    };

    let style = {
      color : current.color
    };

    overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    this.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);

    DataManager.setCurrentDrawingTool(current);
  }

  onMouseUp(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (current.state != DrawingState.Drawing) { return; }

    let endX = e.clientX || e.changedTouches[0].clientX;
    let endY = e.clientY || e.changedTouches[0].clientY;

    let info = {
      x0 : current.x,
      y0 : current.y,
      x1 : endX,
      y1 : endY
    };

    let style = {
      color : current.color
    };

    overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    this.draw(info, style, canvas, canvasContext, true, true, sessionId);

    current.x = e.clientX || e.changedTouches[0].clientX;
    current.y = e.clientY || e.changedTouches[0].clientY;
    current.state = DrawingState.None;

    DataManager.setCurrentDrawingTool(current);
    DataManager.incrementCurrentObjectId();
  }

  drawEmittedData(data,
    canvas, context,
    overlayCanvas, overlayContext,
    userId, sessionId){
      let width = canvas.width;
      let height = canvas.height;

      let info = {
        x0 : data.x0 * width,
        y0 : data.y0 * height,
        x1 : data.x1 * width,
        y1 : data.y1 * height
      }
      let style = {
        color : data.color
      }

      //overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      //overlayContext.clearRect(0, 0, width, height);
      // console.log('Drawing Emitted Line: ' + JSON.stringify(info) + ", Context: " + (!!data.final && !data.selected));
      this.draw(info, style, canvas,
        (!!data.final && !data.selected) ? context : overlayContext,
        data.final, false, sessionId);
  }

  draw(info, style, canvas, context, isFinal, emit, sessionId){
    const { x0, y0, x1, y1 } = info;
    const { color = 'black' } = style;
    context.beginPath();
    context.moveTo(x0, y0);
    context.lineTo(x1, y1);
    context.strokeStyle = color;
    context.lineWidth = 2;
    context.stroke();
    context.closePath();

    if (!emit) { return; }

    let w = canvas.width;
    let h = canvas.height;
    let userId = DataManager.getUserId();
    let drawObjectId = DataManager.getCurrentObjectId();
    let current = DataManager.getCurrentDrawingTool();

    EventManager.onDrawingDataEmit({
      sessionId: sessionId,
      userId: userId,   //userID will be set only when emit is set to true, meaning user drew the line
      actingUserId: userId,
      objectId: drawObjectId,
      control: DrawingControls.StraightLine,
      final: isFinal,
      selected: false,
      drawingState: current.state,
      x0: x0 / w,
      y0: y0 / h,
      x1: x1 / w,
      y1: y1 / h,
      color,
    });
  }
}

const instance = new StraightLineTool();
Object.freeze(instance);
export default instance;
//export default StraightLineTool;
