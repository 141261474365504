import React, { useRef, useEffect, useState} from 'react';
import CameraViewControl from "../controls/cameraViewControl";

import { useParams, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';

const GA_MEASUREMENT_ID = "G-4W1W2FGV07";

const CameraPage = () => {
  const [sessionData, setSessionData] = useState({});
  const { mysession, myuser } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if(!!mysession){
      setSessionData({sessionId: mysession, userId: myuser});
    }

    window.gtag("config", GA_MEASUREMENT_ID, {
      page_path: window.location.pathname,
    });
  },[]);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Use the full viewport height
      }}>
      <CameraViewControl
        width={640}
        height={480}
        visible={true}
        session={sessionData.sessionId}
        user={sessionData.userId}/>
    </div>
  );
};

export default CameraPage;
