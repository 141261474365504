import React from "react";

// We use Route in order to define the different routes of our application
import { Route, Routes } from "react-router-dom";

// We import all the components we need in our app
//mport BoardPage from "./components/boardPage";
import BoardPage2 from "./components/boardPage2";
import BoardPage  from "./components/pages/boardPage";
import CameraPage  from "./components/pages/cameraPage";

const App = () => {

 //TODO: pass session param to the page class being created

 //TODO: board page without a session will reach out
// grab a session id and redirect to the page with the session

/*
<Route exact path="/board2" element={<BoardPage2 />} />
<Route exact path="/board2/:mysession" element={<BoardPage2 />} />
*/

 return (
   <div>
     <Routes>
       <Route exact path="/" element={<BoardPage />} />
       <Route exact path="/:mysession" element={<BoardPage />} />
       <Route exact path="/board" element={<BoardPage />} />
       <Route exact path="/camera/:mysession" element={<CameraPage />} />
       <Route exact path="/camera/:mysession/:myuser" element={<CameraPage />} />
     </Routes>
   </div>
 );
};

export default App;
