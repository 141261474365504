import React, { useState, useRef, useEffect } from 'react';
import DataManager, {DrawingState, DrawingControls} from "../data/dataManager";
import ImageTool from "../data/tools/imageTool";
import "../../styles/camera_page.css";

//TODO: ADD UPLOAD PHOTO BUTTON

const CameraViewControl = ({visible, width, height, session, user}) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const camOverlayRef = useRef(null);

  const [camControlProperties, setCamControlProperties] = useState({
    visible : visible,
    x : 0,
    y : 0,
    width : width,
    height : height
  });
  const [redrawFlag, setRedrawFlag] = useState(false);

  const setCamControlVisible = (visible, x, y, width, height) => {
    setCamControlProperties({
      visible : visible,
      x : (window.innerWidth > 950) ? x : x / DataManager.getMobilePixelMultiplier(),
      y : (window.innerWidth > 950) ? y : y / DataManager.getMobilePixelMultiplier(),
      width : (window.innerWidth > 950) ? width : width / DataManager.getMobilePixelMultiplier(),
      height : (window.innerWidth > 950) ? height : height / DataManager.getMobilePixelMultiplier()
    });
  };

  useEffect(() => {
    if(window.innerWidth <= 950){
      ImageTool.setVisibleCallback('camViewTool', setCamControlVisible);
      ImageTool.setRedrawCallback('camViewTool', setRedrawFlag);
    }
    // Access the user's camera
    let constraints = {
        video: {
          facingMode : "environment" //"user"
        }
    };
    // console.log('CAM VISIBLE: ' + JSON.stringify(camControlProperties))
    if (navigator.mediaDevices
      && navigator.mediaDevices.getUserMedia
      && !!camControlProperties.visible) {
      //navigator.mediaDevices.getUserMedia({ video: true })

      const overlayCamCanvas = camOverlayRef.current;
      const ctx = overlayCamCanvas.getContext('2d');

      // Text style configuration
      ctx.fillStyle = 'red';
      ctx.font = '16px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      // Calculate center position
      const centerX = overlayCamCanvas.width / 2;
      const centerY = overlayCamCanvas.height / 2;

      // Draw the text
      ctx.fillText("Tap to take a picture", centerX, centerY);

      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          if (videoRef.current) videoRef.current.srcObject = stream;
        })
        .catch(console.error);

      // Cleanup function to stop the camera stream
      return () => {
        if (videoRef.current && videoRef.current.srcObject) {
          videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        }
      };
    }
  }, []);

  useEffect(() => {
    // Access the user's camera
    let constraints = {
        video: {
          facingMode : "environment" //"user"
        }
    };
    // console.log('CAM VISIBLE: ' + JSON.stringify(camControlProperties))
    if (navigator.mediaDevices
      && navigator.mediaDevices.getUserMedia
      && !!camControlProperties.visible) {
      //navigator.mediaDevices.getUserMedia({ video: true })

      const overlayCamCanvas = camOverlayRef.current;
      const ctx = overlayCamCanvas.getContext('2d');

      // Text style configuration
      ctx.fillStyle = 'red';
      ctx.font = '16px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      // Calculate center position
      const centerX = overlayCamCanvas.width / 2;
      const centerY = overlayCamCanvas.height / 2;

      // Draw the text
      ctx.fillText("Tap to take a picture", centerX, centerY);

      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          if (videoRef.current) videoRef.current.srcObject = stream;
        })
        .catch(console.error);

      // Cleanup function to stop the camera stream
      return () => {
        if (videoRef.current && videoRef.current.srcObject) {
          videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        }
      };
    }
  }, [camControlProperties])

  const takePicture = () => {
    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0,
      camControlProperties.width, camControlProperties.height);
    canvasRef.current.toBlob(sendPhotoToServer, 'image/jpeg');
  };

  const sendPhotoToServer = (blob) => {
    //let hostname = DataManager.getCurrentHostName();
    let hostname = "colabt.com";
    const formData = new FormData();
    formData.append('photo', blob);
    formData.append('session', session);
    formData.append('user', user);

    // console.log('POSTING FORM: ' + JSON.stringify(formData));

    fetch('https://'+hostname+':8080/action/upload/image', {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(console.error);
  };

  let camStyle = (!camControlProperties.x)
    ? {
        position: 'relative',
        width: camControlProperties.width+'px',
        height: camControlProperties.height+'px'}
    : {
      position: 'absolute',
      top : camControlProperties.y,
      left : camControlProperties.x,
      width: camControlProperties.width+'px',
      height: camControlProperties.height+'px'
    }

  return (
    <div style={{
      visibility: !!camControlProperties.visible ? 'visible' : 'collapse',
    }}>
      <div style={camStyle}>
        <video ref={videoRef} autoPlay style={{
          width:  '100%',
          height: '100%',
          borderColor: 'grey',
          borderWidth: '2px',
          borderStyle: 'solid',
          zIndex: 1
        }}></video>
        <canvas ref={camOverlayRef} style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          zIndex: 3
         }}
          onClick={takePicture}></canvas>
      </div>
      <canvas ref={canvasRef} style={{display: 'none'}} width={camControlProperties.width} height={camControlProperties.height}></canvas>
    </div>
  );
};

export default CameraViewControl;

/*
// <div style={{
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   height: '70px'
// }}>
//   <button className="ctbutton" onClick={takePicture}>Take Picture</button>
// </div>
*/
