import React, { useRef, useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave, faSlash, faPen,
  faSquareFull, faFont,
  faArrowPointer, faRotateLeft, faFilePdf, faEraser,
  faBorderStyle, faLongArrowUp, faCamera, faShareNodes, faUpload, faFolderOpen} from '@fortawesome/free-solid-svg-icons';
import {faSquare, faClipboard, faSquareDashed, faFile} from '@fortawesome/free-regular-svg-icons';
import DataManager, {DrawingState, DrawingControls, ControlActions} from "../data/dataManager";

import "../../styles/controls_toolbar.css";

const ControlsToolBar = () => {
  const inputJsonRef = useRef(null);

  const [currentColor, setCurrentColor] = useState("black");
  const [currentDrawingTool, setCurrentDrawingTool] = useState({
     x:0,
     y:0,
     tool: DrawingControls.Pointer, //DrawingControls.Line,
     state: DrawingState.None
   });
   const [windowDimensions, setWindowDimensions] = useState({
     width : window.innerWidth,
     height : window.innerHeight
   });
   const [displayMenu, setDisplayMenu] = useState(false);
   const [displayNotificationBar, setDisplayNotificationBar] = useState(false);
   const [displaySaveMenu, setDisplaySaveMenu] = useState(false);

   const onUndoAction = (e) => {
     // console.log('onUndoAction called ');
     let actionCallback = DataManager.getActionCallbackForAction(ControlActions.Undo);
     actionCallback();
   };

   const onPdfAction = (e) => {
     // console.log('onPdfAction called');
     let actionCallback = DataManager.getActionCallbackForAction(ControlActions.SavePdf);
     actionCallback();
     DataManager.setDisplaySaveMenu(false);
   };

   const onDownloadAction = (e) => {
     // console.log('onDownloadAction called');
     let actionCallback = DataManager.getActionCallbackForAction(ControlActions.Download);
     actionCallback();
     DataManager.setDisplaySaveMenu(false);
   };

   const onSaveMenuClick = (e) => {
     DataManager.setDisplaySaveMenu(!displaySaveMenu);
   }

   const handleFileChange = (event) => {
      const file = event.target.files[0]; // Get the first file
      if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            let data = JSON.parse(e.target.result); //e.target.result; //JSON.parse(e.target.result);
            let actionCallback = DataManager.getActionCallbackForAction(ControlActions.Upload);
            actionCallback(data);
          };
          reader.onerror = () => {
              console.error("Error reading the file");
              alert("There was an error reading the file.");
          };
          reader.readAsText(file); // Read the file as text
      }
   };

   const onUploadAction = (e) => {
     inputJsonRef.current.click();
   };

   const shareUrl = () => {
      if(navigator.share) {
        navigator.share({
          title: 'Join ColabT Session', // Title of the content to share
          url: window.location.href // Current URL to share
        })
        .then(() => console.log('Content shared successfully!'))
        .catch((error) => console.error('Error sharing content:', error));
      }
   };

   const handleControlMouseOver = (e, text) => {
     DataManager.setToolTipState({
       x      : e.clientX,
       y      : e.clientY,
       text   : text,
       visible: true
     });
   };

   const handleControlMouseOut = (e) => {
     DataManager.setToolTipState({
       visible: false
     });
   };

  useEffect(() => {
    DataManager.addCurrentColorCallback("controlsToolBar", setCurrentColor);
    DataManager.addCurrentDrawingToolCallback("controlsToolBar", setCurrentDrawingTool);
    DataManager.addWindowResizeCallback("controlsToolBar", setWindowDimensions);
    DataManager.addDismissMobileMenuCallback("controlsToolBar",
      () => {
        setDisplayMenu(false);
      });

    DataManager.addNotificationTextCallback("controlsToolBar", (text) => {
      setDisplayNotificationBar(!!text && text.length > 0);
    });
    DataManager.addDisplaySaveMenuCallback("controlsToolBar", setDisplaySaveMenu);

    DataManager.setCurrentDrawingTool(currentDrawingTool);
  }, []);

  useEffect(() => {

    if(windowDimensions.width <= 950){
      return;
    }

    const controlButtons = document.getElementsByClassName('control');
    for (let i = 0; i < controlButtons.length; i++) {
      controlButtons[i].addEventListener('click', () => {
        let drawingControl = controlButtons[i].className.split(' ')[2];
        let currentColor = DataManager.getCurrentColor();
        let currentTool = {
          x: 0,   //TODO: retrieve current.x and current.y
          y: 0,
          tool: drawingControl,
          state: DrawingState.None,
          color: currentColor
        };

        DataManager.setCurrentDrawingTool(currentTool);
      });
    }
  }, [windowDimensions]);


  useEffect(() => {
    if(!displayMenu){
      return;
    }

    const controlButtons = document.getElementsByClassName('control');
    for (let i = 0; i < controlButtons.length; i++) {
      controlButtons[i].addEventListener('click', () => {
        let drawingControl = controlButtons[i].className.split(' ')[2];
        let currentColor = DataManager.getCurrentColor();
        let currentTool = {
          x: 0,   //TODO: retrieve current.x and current.y
          y: 0,
          tool: drawingControl,
          state: DrawingState.None,
          color: currentColor
        };

        DataManager.setCurrentDrawingTool(currentTool);
        setDisplayMenu(false);
      });
    }
  },[displayMenu]);



  let saveMenuControl = (!!displaySaveMenu) ?
    (<div className="savemenu overlay" style={{
      top: "105px",//"90px",
      zIndex: 5000
    }}>
      <div className="btn pdf" onClick={onPdfAction} style={{
        borderRight : "1px",
        borderRightStyle : "solid",
        borderRightColor : "grey"
      }}><FontAwesomeIcon icon={faFilePdf} aria-hidden="true"/></div>
      <div className="btn download" onClick={onDownloadAction}>
      <FontAwesomeIcon icon={faFile} aria-hidden="true"/></div>
    </div>) : null;

  let selectedControlIcon = (<FontAwesomeIcon icon={faPen}/>);
  if(currentDrawingTool.tool == DrawingControls.Line){
    selectedControlIcon = (<FontAwesomeIcon icon={faPen}/>);
  }
  if(currentDrawingTool.tool == DrawingControls.StraightLine){
    selectedControlIcon = (<FontAwesomeIcon icon={faSlash}/>);
  }
  if(currentDrawingTool.tool == DrawingControls.StraightArrow){
    selectedControlIcon = (<FontAwesomeIcon icon={faLongArrowUp}/>);
  }
  if(currentDrawingTool.tool == DrawingControls.Rect){
    selectedControlIcon = (<FontAwesomeIcon icon={faSquare} />);
  }
  if(currentDrawingTool.tool == DrawingControls.FilledRect){
    selectedControlIcon = (<FontAwesomeIcon icon={faSquareFull} />);
  }
  if(currentDrawingTool.tool == DrawingControls.Text){
    selectedControlIcon = (<FontAwesomeIcon icon={faFont} />);
  }
  if(currentDrawingTool.tool == DrawingControls.Eraser){
    selectedControlIcon = (<FontAwesomeIcon icon={faEraser} />);
  }
  if(currentDrawingTool.tool == DrawingControls.Selector){
    selectedControlIcon = (<FontAwesomeIcon icon={faBorderStyle} />);
  }
  if(currentDrawingTool.tool == DrawingControls.Image){
    selectedControlIcon = (<FontAwesomeIcon icon={faCamera} />);
  }
  if(currentDrawingTool.tool == DrawingControls.Pointer){
    selectedControlIcon = (<FontAwesomeIcon icon={faArrowPointer} />);
  }


  const labelMap = {
    "clear"           : { text: "Undo"  },
    "download"        : { text: "Save"  },
    "upload"          : { text: "Open"  },
    "line"            : { text: "Pen"         },
    "straight-line"   : { text: "Line"        },
    "straight-arrow"  : { text: "Arrow"       },
    "rect"            : { text: "Rect"        },
    "filled-rect"     : { text: "Filled Rect" },
    "text"            : { text: "Text" },
    "eraser"          : { text: "Eraser" },
    "selector"        : { text: "Selector" },
    "image"           : { text: "Image"},
    "pointer"         : { text: "Pointer" },
    "control_menu"    : { text: "Controls"},
    "pdf"             : { text: "Save"},
    "share"           : { text: "Share"}
  };

  const getLabelControl = (key, x, y, inheritTextFont) => {
    let controlStyle = {
      position: "fixed",
      top : y + "px",
      left: x + "px",
      textAlign: "center",
      width: "65px",
      fontSize: "14px",
      zIndex: 6000
    };
    if(!inheritTextFont){
      controlStyle.color = "grey";
    }
    return (<div style={controlStyle}>{labelMap[key].text}</div>);
  };

  let menuControl = (displayMenu && windowDimensions.width <= 950)
    ? (<div className="buttons overlay" style={{
      width: "210px",
      top: "65px", //"50px",
      backgroundColor: "white",
      zIndex: 5000
    }}>
      <div className="btn control line"
      onMouseOver={(e) => handleControlMouseOver(e, 'Pen')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Line)
        ? {
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
          fontWeight : "bold"
        } : {}}><FontAwesomeIcon icon={faPen}/></div>
      <div className="btn control straight-line"
      onMouseOver={(e) => handleControlMouseOver(e, 'Line')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.StraightLine)
        ? {
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink")  ? "black" : "transparent",
          fontWeight : "bold"
        } : {}}><FontAwesomeIcon icon={faSlash} /></div>
      <div className="btn control straight-arrow"
      onMouseOver={(e) => handleControlMouseOver(e, 'Arrow')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.StraightArrow)
        ? {
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink")  ? "black" : "transparent",
          fontWeight : "bold"
        } : {}}><FontAwesomeIcon icon={faLongArrowUp} /></div>
      <div className="btn control rect"
      onMouseOver={(e) => handleControlMouseOver(e, 'Rectangle')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Rect)
        ? {
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
          fontWeight : "bold"
        } : {}}><FontAwesomeIcon icon={faSquare} /></div>
      <div className="btn control filled-rect"
      onMouseOver={(e) => handleControlMouseOver(e, 'Filled Rectangle')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.FilledRect)
        ? {
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
          fontWeight : "bold"
        } : {}}><FontAwesomeIcon icon={faSquareFull} /></div>
      <div className="btn control text"
      onMouseOver={(e) => handleControlMouseOver(e, 'Text')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Text)
        ? {
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
          fontWeight : "bold"
         } : {}}><FontAwesomeIcon icon={faFont} /></div>
      <div className="btn control eraser"
      onMouseOver={(e) => handleControlMouseOver(e, 'Eraser')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Eraser)
          ? {
            color : currentColor,
            backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
            fontWeight : "bold"
           } : {}}><FontAwesomeIcon icon={faEraser} /></div>
      <div className="btn control selector"
      onMouseOver={(e) => handleControlMouseOver(e, 'Select')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Selector)
            ? {
              color : currentColor,
              backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
              fontWeight : "bold"
             } : {}}><FontAwesomeIcon icon={faBorderStyle} /></div>
      <div className="btn control image"
      onMouseOver={(e) => handleControlMouseOver(e, 'Image/Photo')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Image)
          ? {
            color : currentColor,
            backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
            fontWeight : "bold"
           } : {}}><FontAwesomeIcon icon={faCamera} /></div>
      <div className="btn control pointer"
      onMouseOver={(e) => handleControlMouseOver(e, 'Pointer')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Pointer)
        ? {
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
          fontWeight : "bold"
         }
        : {
          color : "grey"
        }}><FontAwesomeIcon icon={faArrowPointer} /></div>
    </div>) : null;

  let controlToDisplay = (windowDimensions.width > 950)
    ? (<div className="buttons overlay" style={{
        top : !!displayNotificationBar ? "105px" : "65px", //"90px" : "50px",
        zIndex : 5000,
        backgroundColor: 'white'
      }}>
      <div className="btn clear" onClick={onUndoAction}
      onMouseOver={(e) => handleControlMouseOver(e, 'Undo')}
      onMouseOut={handleControlMouseOut}
      style={{
        borderRight : "1px",
        borderRightStyle : "solid",
        borderRightColor : "grey"
      }}><FontAwesomeIcon icon={faRotateLeft} aria-hidden="true"/>
      {getLabelControl("clear", ((0 * 65) + 10), 40, true)}</div>
      <div className="btn download" onClick={onSaveMenuClick}
      onMouseOver={(e) => handleControlMouseOver(e, 'Save')}
      onMouseOut={handleControlMouseOut}
      style={{
        borderRight : "1px",
        borderRightStyle : "solid",
        borderRightColor : "grey"
      }}><FontAwesomeIcon icon={faSave} aria-hidden="true"/>
      {getLabelControl("download", ((1 * 65) + 10), 40, true)}</div>
      <div className="btn upload" onClick={onUploadAction}
      onMouseOver={(e) => handleControlMouseOver(e, 'Open')}
      onMouseOut={handleControlMouseOut}
      style={{
        borderRight : "1px",
        borderRightStyle : "solid",
        borderRightColor : "grey"
      }}><FontAwesomeIcon icon={faFolderOpen} aria-hidden="true"/>
      {getLabelControl("upload", ((2 * 65) + 10), 40, true)}</div>
      <div className="btn control line"
      onMouseOver={(e) => handleControlMouseOver(e, 'Pen')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Line)
        ? {
          borderRight : "1px",
          borderRightStyle : "solid",
          borderRightColor : "grey",
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
          fontWeight : "bold"
        }
        : {
          borderRight : "1px",
          borderRightStyle : "solid",
          borderRightColor : "grey"
        }}><FontAwesomeIcon icon={faPen}/>
        {getLabelControl(DrawingControls.Line, ((3 * 65) + 10), 40, true)}</div>
      <div className="btn control straight-line"
      onMouseOver={(e) => handleControlMouseOver(e, 'Line')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.StraightLine)
        ? {
          borderRight : "1px",
          borderRightStyle : "solid",
          borderRightColor : "grey",
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink")  ? "black" : "transparent",
          fontWeight : "bold"
        }
        : {
          borderRight : "1px",
          borderRightStyle : "solid",
          borderRightColor : "grey"
        }}><FontAwesomeIcon icon={faSlash} />
        {getLabelControl(DrawingControls.StraightLine, ((4 * 65) + 10), 40, true)}</div>
      <div className="btn control straight-arrow"
      onMouseOver={(e) => handleControlMouseOver(e, 'Arrow')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.StraightArrow)
        ? {
          borderRight : "1px",
          borderRightStyle : "solid",
          borderRightColor : "grey",
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink")  ? "black" : "transparent",
          fontWeight : "bold"
        }
        : {
          borderRight : "1px",
          borderRightStyle : "solid",
          borderRightColor : "grey"
        }}><FontAwesomeIcon icon={faLongArrowUp} />
        {getLabelControl(DrawingControls.StraightArrow, ((5 * 65) + 10), 40, true)}</div>
      <div className="btn control rect"
      onMouseOver={(e) => handleControlMouseOver(e, 'Rect')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Rect)
        ? {
          borderRight : "1px",
          borderRightStyle : "solid",
          borderRightColor : "grey",
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
          fontWeight : "bold"
        }
        : {
          borderRight : "1px",
          borderRightStyle : "solid",
          borderRightColor : "grey"
        }}><FontAwesomeIcon icon={faSquare} />
        {getLabelControl(DrawingControls.Rect, ((6 * 65) + 10), 40, true)}</div>
      <div className="btn control filled-rect"
      onMouseOver={(e) => handleControlMouseOver(e, 'Filled Rect')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.FilledRect)
        ? {
          borderRight : "1px",
          borderRightStyle : "solid",
          borderRightColor : "grey",
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
          fontWeight : "bold"
        }
        : {
          borderRight : "1px",
          borderRightStyle : "solid",
          borderRightColor : "grey"
        }}><FontAwesomeIcon icon={faSquareFull} />
        {getLabelControl(DrawingControls.FilledRect, ((7 * 65) + 10), 40, true)}</div>
      <div className="btn control text"
      onMouseOver={(e) => handleControlMouseOver(e, 'Text')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Text)
        ? {
          borderRight : "1px",
          borderRightStyle : "solid",
          borderRightColor : "grey",
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
          fontWeight : "bold"
         }
        : {
          borderRight : "1px",
          borderRightStyle : "solid",
          color : "grey"
        }}><FontAwesomeIcon icon={faFont} />
        {getLabelControl(DrawingControls.Text, ((8 * 65) + 10), 40, true)}</div>
      <div className="btn control eraser"
      onMouseOver={(e) => handleControlMouseOver(e, 'Eraser')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Eraser)
          ? {
            borderRight : "1px",
            borderRightStyle : "solid",
            borderRightColor : "grey",
            color : currentColor,
            backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
            fontWeight : "bold"
           }
          : {
            borderRight : "1px",
            borderRightStyle : "solid",
            color : "grey"
        }}><FontAwesomeIcon icon={faEraser} />
        {getLabelControl(DrawingControls.Eraser, ((9 * 65) + 10), 40, true)}</div>
        <div className="btn control selector"
        onMouseOver={(e) => handleControlMouseOver(e, 'Select')}
        onMouseOut={handleControlMouseOut}
        style={(currentDrawingTool.tool == DrawingControls.Selector)
            ? {
              borderRight : "1px",
              borderRightStyle : "solid",
              borderRightColor : "grey",
              color : currentColor,
              backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
              fontWeight : "bold"
             }
            : {
              borderRight : "1px",
              borderRightStyle : "solid",
              color : "grey"
          }}><FontAwesomeIcon icon={faBorderStyle} />
          {getLabelControl(DrawingControls.Selector, ((10 * 65) + 10), 40, true)}</div>
      <div className="btn control image"
      onMouseOver={(e) => handleControlMouseOver(e, 'Image/Photo')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Image)
          ? {
            borderRight : "1px",
            borderRightStyle : "solid",
            borderRightColor : "grey",
            color : currentColor,
            backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
            fontWeight : "bold"
           }
          : {
            borderRight : "1px",
            borderRightStyle : "solid",
            color : "grey"
         }}><FontAwesomeIcon icon={faCamera} />
         {getLabelControl(DrawingControls.Image, ((11 * 65) + 10), 40, true)}</div>
      <div className="btn control pointer"
      onMouseOver={(e) => handleControlMouseOver(e, 'Pointer')}
      onMouseOut={handleControlMouseOut}
      style={(currentDrawingTool.tool == DrawingControls.Pointer)
        ? {
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
          fontWeight : "bold",
          borderTopRightRadius: '15px',
          borderBottomRightRadius: '15px'
         }
        : {
          color : "grey"
        }}><FontAwesomeIcon icon={faArrowPointer} />
        {getLabelControl(DrawingControls.Pointer, ((12 * 65) + 10), 40, true)}</div>
    </div>)
        : (<div className="buttonsmenu overlay" style={{
              backgroundColor: 'white',
              zIndex: 5000
            }}>
              <div className="btn control_menu" style={{
                  color : currentColor,
                  backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
                  //fontWeight : "bold",
                  borderRight : "1px",
                  borderRightStyle : "solid",
                  borderRightColor : "grey",
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px'
                }}
                onMouseOver={(e) => handleControlMouseOver(e, 'Drawing Controls')}
                onMouseOut={handleControlMouseOut}
                onClick={() => {
                  setDisplayMenu(!displayMenu);
                  DataManager.dismissAllMobileMenusExceptKey("controlsToolBar");
                }}>{selectedControlIcon}{getLabelControl("control_menu", ((0 * 65) + 51), 0, false)}
              </div>
              <div className="btn clear" onClick={onUndoAction}
              onMouseOver={(e) => handleControlMouseOver(e, 'Undo')}
              onMouseOut={handleControlMouseOut}
              style={{
                borderRight : "1px",
                borderRightStyle : "solid",
                borderRightColor : "grey"
              }}><FontAwesomeIcon icon={faRotateLeft} aria-hidden="true"/>
              {getLabelControl("clear", ((1 * 65) + 51), 0, false)}</div>
              <div className="btn pdf"
              onMouseOver={(e) => handleControlMouseOver(e, 'Save')}
              onMouseOut={handleControlMouseOut}
              onClick={onPdfAction} style={{
                borderRight : "1px",
                borderRightStyle : "solid",
                borderRightColor : "grey"
              }}><FontAwesomeIcon icon={faFilePdf} aria-hidden="true"/>
              {getLabelControl("pdf", ((2 * 65) + 51), 0, false)}</div>
              <div className="btn share"
              onMouseOver={(e) => handleControlMouseOver(e, 'Share')}
              onMouseOut={handleControlMouseOut}
              onClick={shareUrl}><FontAwesomeIcon icon={faShareNodes} aria-hidden="true"/>
              {getLabelControl("share", ((3 * 65) + 51), 0, false)}</div>
          </div>);


  return (
    <div>
    <input
        type="file"
        ref={inputJsonRef}
        accept=".json, .colabt"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    {controlToDisplay}
    {menuControl}
    {saveMenuControl}
    </div>);
};

export default ControlsToolBar;
